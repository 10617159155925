import React from 'react';

import { observer } from 'mobx-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from '../Alert';
import EmailInput from '../EmailInput';

import './Signup.css';

import { FormattedMessage } from 'react-intl';

import { trackBingEvent } from 'src/analytics/bing';
import { trackGoogleSignup } from 'src/analytics/google';
import { injectIntl } from 'src/i18n';
import { SubscriptionTrackerStore } from 'src/subs';
import injectStore from 'src/util/injectStore';
import { messages } from './messages';
import { handleConsent } from './utils';

@injectStore
@injectIntl
@observer
export class SignupV1 extends React.Component<any> {
  state: { consent: boolean } = {
    consent: false
  };

  componentDidMount(): void {
    const emailSearchParam = new URLSearchParams(window.location.search).get('email');
    const user = this.props.store.user;

    if (user && emailSearchParam) {
      user.signupData.email = emailSearchParam;
    }
  }

  async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const signUpResult = await this.props.store.user.signup();
    if (!signUpResult) {
      return;
    }
    trackGoogleSignup();
    trackBingEvent('signup');
    await handleConsent(this.state.consent);
    await this.props.postSignupCallback?.();
  }

  handleNav(name: string, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();

    if (name === 'home') {
      this.props.store.nav('invoiceList');
      return;
    }

    this.props.store.nav(name, undefined, true);
  }

  render() {
    const store = this.props.store;
    const user = store.user;
    const data = user.signupData;
    const f = this.props.intl.formatMessage;

    return (
      <div className="signup">
        <div className="focus-container">
          <div className="focus-header">
            <a className="brand" href="/" onClick={(e) => this.handleNav('home', e)}>
              <img
                className="brand-logo"
                src="/images/logo.png"
                role="presentation"
                alt="invoice simple logo"
                data-pin-nopin="true"
              />
            </a>
            <h1>
              <FormattedMessage {...messages.header} />
            </h1>
            <h2>
              <FormattedMessage {...messages.subHeader} />
            </h2>
          </div>
          <div className="focus-body">
            {SubscriptionTrackerStore.isVerifying ? (
              <div className="alert-loading d-flex justify-content-center pb-4">
                <FontAwesomeIcon icon="spinner" size="lg" color="black" spin />
              </div>
            ) : (
              <Alert />
            )}
            <form acceptCharset="UTF-8" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="form-group">
                <label htmlFor="firstName">
                  <FormattedMessage {...messages.formLabelFirstName} />
                </label>
                <input
                  id="firstName"
                  type="text"
                  placeholder={f(messages.formPlaceholderFirstName)}
                  disabled={user.isSigningUp}
                  value={data.firstName}
                  onChange={(e) => (data.firstName = e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">
                  <FormattedMessage {...messages.formLabelLastName} />
                </label>
                <input
                  id="lastName"
                  type="text"
                  placeholder={f(messages.formPlaceholderLastName)}
                  disabled={user.isSigningUp}
                  value={data.lastName}
                  onChange={(e) => (data.lastName = e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  <FormattedMessage {...messages.formLabelEmail} />
                </label>
                <EmailInput
                  key="user-signup"
                  name="email"
                  placeholder={
                    SubscriptionTrackerStore.isVerifying
                      ? f(messages.formButtonLabelLoading)
                      : f(messages.formPlaceholderEmail)
                  }
                  disabled={SubscriptionTrackerStore.isVerifying || user.isSigningUp}
                  value={data.email}
                  onChange={(e) => (data.email = e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">
                  <FormattedMessage {...messages.formLabelPassword} />
                </label>
                <input
                  id="password"
                  type="password"
                  placeholder="******"
                  disabled={user.isSigningUp}
                  value={data.password}
                  onChange={(e) => (data.password = e.target.value)}
                />
              </div>
              <div id="showmore" className="consent-management">
                <div className="consent-checkbox">
                  <input
                    id="consent"
                    type="checkbox"
                    data-testid="consent-checkbox"
                    checked={this.state.consent}
                    onChange={(e) => {
                      this.setState({ consent: e.target.checked });
                    }}
                    disabled={user.isSigningUp}
                  />
                </div>
                <div>
                  <p>
                    <FormattedMessage {...messages.consentVisible} />
                    <span className="showmore-content" data-testid="showmore-content">
                      {' '}
                      <FormattedMessage {...messages.consentHidden} />
                    </span>
                    <span className="showmore-trigger">
                      ...{' '}
                      <a href="#showmore" className="showmore-link">
                        <FormattedMessage {...messages.consentShowMore} />
                      </a>
                    </span>
                  </p>
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block btn-prime"
                  disabled={SubscriptionTrackerStore.isVerifying || user.isSigningUp}>
                  {user.isSigningUp ? (
                    <FormattedMessage {...messages.formButtonLabelPending} />
                  ) : user.isSigningUp ? (
                    <FormattedMessage {...messages.formButtonLabelLoading} />
                  ) : (
                    <FormattedMessage {...messages.formButtonLabel} />
                  )}
                </button>
                <a className="btn btn-cancel" onClick={(e) => this.handleNav('home', e)}>
                  <FormattedMessage {...messages.cancelButtonLabel} />
                </a>
                <div className="signup-terms">
                  <FormattedMessage {...messages.signupTermsAgreement} />
                </div>
              </div>
            </form>
          </div>
          <div className="focus-footer">
            <FormattedMessage {...messages.loginLinkQuestion} />
            <a
              href="/login"
              className="btn"
              onClick={(e) => this.handleNav('login', e)}
              style={{ marginLeft: 10 }}>
              <FormattedMessage {...messages.loginLinkLabel} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
