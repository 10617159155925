import React, { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet';

import { useISIntl } from 'src/i18n';
import AlertModel from 'src/models/AlertModel';
import LocationModel from 'src/models/LocationModel';
import { handleSignupNav } from 'src/models/UserAuth';
import UserModel from 'src/models/UserModel';
import { startPaypalOnboarding } from 'src/payments';
import { usePaypalContext, useStripeContext } from 'src/payments/contexts';
import { isOnboardingV1Enabled } from 'src/util/onboarding';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import { messages } from './messages';
import { SignupV1 } from './SignupV1';
import { SignupV2 } from './SignupV2';
import { validateReferralSignup } from './utils';

export const Signup = () => {
  const user = UserModel.getInstance();
  const initialRedirect = useRef(false);
  useEffect(() => {
    const handleRedirect = async () => {
      if (user.isLoaded && !initialRedirect.current) {
        initialRedirect.current = true;
        if (user.isSignedUp) {
          const returnPath = getURLQueryParam(URLQueryParamKeys.RETURN_PATH);
          if (returnPath) {
            await handleSignupNav(user);
            return;
          }
          LocationModel.navAndScrollTop('home');
          return;
        }
      }
    };

    handleRedirect();
  }, [user]);

  const { ft } = useISIntl();
  const showPaypalModal = usePaypalContext()?.showPaypalModal;
  const showStripeModal = useStripeContext()?.showStripeModal;

  const [postSignupCallback, setPostSignupCallback] = useState<Function>();

  useEffect(() => {
    const ref = getURLQueryParam(URLQueryParamKeys.REF);
    const params = ref ? { trigger: ref } : {};
    if (isOnboardingV1Enabled()) {
      params['onboarding-version'] = 'v1';
    }
    user.events.trackAction('signup-view', params);
  }, []);

  useEffect(() => {
    const ref = getURLQueryParam(URLQueryParamKeys.REF);
    const referralCode = getURLQueryParam(URLQueryParamKeys.REFERRAL_CODE);

    switch (ref) {
      case URLQueryParamKeys.SEND_DOCUMENT:
        AlertModel.setAlertObject({
          type: 'warning',
          titleMessageId: 'accountRequired',
          isClosable: false
        });
        break;
      case URLQueryParamKeys.CREATE_DOCUMENT:
        AlertModel.setAlertObject({
          type: 'warning',
          titleMessageId: 'accountRequired',
          isClosable: false
        });
        break;
      case URLQueryParamKeys.DOCUMENT_ACTIONS:
        AlertModel.setAlertObject({
          type: 'warning',
          titleMessageId: 'accountRequiredOptions',
          bodyMessageId: 'pleaseSignup'
        });
        break;
      case URLQueryParamKeys.OLD_PAYPAL_ONBOARDING:
        AlertModel.setAlertObject({
          type: 'warning',
          titleMessageId: 'accountRequired',
          isClosable: false
        });
        setPostSignupCallback(() => startPaypalOnboarding);
        break;
      case URLQueryParamKeys.PAYPAL_ONBOARDING:
        AlertModel.setAlertObject({
          type: 'warning',
          titleMessageId: 'accountRequired',
          isClosable: false
        });
        setPostSignupCallback(() => showPaypalModal);
        break;
      case URLQueryParamKeys.STRIPE_ONBOARDING:
        AlertModel.setAlertObject({
          type: 'warning',
          titleMessageId: 'accountRequired',
          isClosable: false
        });
        setPostSignupCallback(() => showStripeModal);
        break;
      case URLQueryParamKeys.INVALID_REFERRAL_LINK:
        AlertModel.setAlertObject({
          type: 'warning',
          titleMessageId: 'invalidReferralLink',
          isClosable: false
        });
        break;
    }

    if (user.isLoaded && referralCode) {
      validateReferralSignup(user, referralCode);
      return;
    }
  }, [user.isLoaded]);

  const onboardingV1Enabled = isOnboardingV1Enabled();
  return (
    <>
      <Helmet>
        <title itemProp="name">{ft(messages.title)}</title>
        <meta name="description" content={ft(messages.description)} />
        {!onboardingV1Enabled && <body className="app-theme app-theme-focus" />}
      </Helmet>
      {onboardingV1Enabled ? (
        <SignupV2 postSignupCallback={postSignupCallback} />
      ) : (
        <SignupV1 postSignupCallback={postSignupCallback} />
      )}
    </>
  );
};
