const getGoogleTag = (): Function | undefined => {
  type WindowWithDataLayer = Window & {
    gtag?: Function;
  };

  return (window as unknown as WindowWithDataLayer)?.gtag;
};

export const trackGoogleAdsConversion = (amount: number, currency: string) => {
  const gtag = getGoogleTag();

  gtag?.('event', 'conversion', {
    send_to: 'AW-1006814914/B0MTCIr10p4ZEMKNi-AD',
    value: amount,
    currency
  });
};

export const trackGoogleFirstInvoiceCreated = () => {
  const gtag = getGoogleTag();

  gtag?.('event', 'conversion', {
    send_to: 'AW-1006814914/K4PyCNao3sUZEMKNi-AD'
  });
};

export const trackGoogleSignup = () => {
  const gtag = getGoogleTag();

  gtag?.('event', 'conversion', {
    send_to: 'AW-1006814914/G8P8CJmQyu8ZEMKNi-AD',
    value: 1.0,
    currency: 'CAD'
  });
};
