import { useFlags } from 'flagsmith/react';

import UserModel from 'src/models/UserModel';

export const useShouldDisplaySaasPayments = () => {
  const { hide_payments_nonsaas_web, payments_enabled } = useFlags([
    'hide_payments_nonsaas_web',
    'payments_enabled'
  ]);

  if (!payments_enabled?.enabled) {
    return false;
  }

  if (hide_payments_nonsaas_web?.enabled && !UserModel.getInstance().hadAnySub) {
    return false;
  }

  return true;
};
