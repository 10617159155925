import type { UpgradeSubscriptionParams } from 'src/stores/AppStore';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { paywallCoupons } from '@invoice-simple/is-coupon';

import { SubscriptionSwitchType } from 'src/components/SubscriptionPaywall/utils';
import UserModel, { Cadence } from 'src/models/UserModel';

export const errorMessages = {
  crossPlatform: 'Cannot apply cross-platform discount',
  skuMismatch: 'Last paid sku does not match current sku',
  missingTier: 'Tier is missing in User store',
  subscriptionUpdateError: 'Subscription update error'
};

interface WebDiscountArgs {
  user: UserModel;
  coupon: string;
  couponSku: string;
  upgradeSubscription: (args: UpgradeSubscriptionParams) => Promise<void>;
}

export const useWebDiscountMutation = (
  options?: UseMutationOptions<unknown, unknown, WebDiscountArgs>
) => {
  return useMutation({
    mutationFn: applyWebSubscriptionDiscount,
    ...options
  });
};

const applyWebSubscriptionDiscount = async (args: WebDiscountArgs) => {
  const lastActivePaidWebSub = args.user.lastActivePaidWebSub();
  const mobileSubscription = args.user.lastActivePaidMobileSub();
  if (mobileSubscription) {
    throw new Error(errorMessages.crossPlatform);
  }

  if (lastActivePaidWebSub?.orderSku !== args.user.subscriptionOrderSku) {
    throw new Error(errorMessages.skuMismatch);
  }

  if (!lastActivePaidWebSub.tier) {
    throw new Error(errorMessages.missingTier);
  }

  try {
    await args.upgradeSubscription({
      subscriptionToSwitch: lastActivePaidWebSub,
      newOrderSku: lastActivePaidWebSub.orderSku,
      switchType: SubscriptionSwitchType.CROSSGRADE,
      coupon: args.coupon,
      couponSku: args.couponSku,
      newPrice: args.user.getDiscountTierPrice({
        tier: lastActivePaidWebSub.tier,
        cadence: Cadence.MONTHLY,
        paywallCouponConfig: paywallCoupons.cancelPromo50off3months
      })
    });
  } catch (e) {
    throw new Error(errorMessages.subscriptionUpdateError);
  }
};
