import type { UpgradeSubscriptionParams } from 'src/stores/AppStore';
import React from 'react';

import { Platform } from '@invoice-simple/common';

import { LoadingSpinnerIcon } from 'src/components/Icons';
import alertModel from 'src/models/AlertModel';
import LocationModel from 'src/models/LocationModel';
import UserModel from 'src/models/UserModel';
import { useCancelPromo } from '../CancelPromoContext';
import { errorMessages, useWebDiscountMutation } from '../mutations/useWebDiscountMutation';
import { CANCEL_PROMO_COUPON } from '../types';
import { trackMobileWebviewEvent } from '../utils/trackMobileWebviewEvent';

interface Props {
  user: UserModel;
  upgradeSubscription: (args: UpgradeSubscriptionParams) => Promise<void>;
}

export const Discount = (props: Props) => {
  const { cancelPromoEnabled, couponSku, currentSku, platform } = useCancelPromo();
  const { mutate, isLoading, isSuccess } = useWebDiscountMutation({
    onError: (error: Error) => {
      if (error.message === errorMessages.crossPlatform) {
        const storeName = props.user.isSubAndroid ? 'Google Play Store' : 'Apple App Store';
        alertModel.setAlert(
          'danger',
          `Your subscription is managed through the ${storeName}`,
          'Please try again on your mobile device'
        );
        LocationModel.scrollTop();
        return;
      }
      alertModel.setAlert(
        'danger',
        'Unable to apply discount',
        'Please try again or contact support'
      );
      LocationModel.scrollTop();
    },
    onSuccess: () => {
      window.location.assign(`${process.env.REACT_APP_URL}/invoices?ref=cancel-promo`);
    }
  });

  if (!cancelPromoEnabled || !couponSku || !currentSku) {
    return null;
  }

  const onApplyDiscount = async () => {
    if (platform === Platform.WEB) {
      props.user.events.trackAction('subscription-cancel-applyDiscountButtonClicked', {
        'coupon-sku': CANCEL_PROMO_COUPON
      });
      mutate({
        user: props.user,
        coupon: CANCEL_PROMO_COUPON,
        couponSku,
        upgradeSubscription: props.upgradeSubscription
      });
      return;
    }

    trackMobileWebviewEvent({
      event: 'webview',
      meta: {
        feature: 'cancellation-survey',
        action: 'apply-discount',
        platform,
        'coupon-sku': CANCEL_PROMO_COUPON
      }
    });
    window.location.href = `purchase://${currentSku}//${couponSku}`;
  };

  const showLoading = isLoading || isSuccess;
  return (
    <div
      className={`flex content-center flex-col space-y-6 self-center ${
        platform === Platform.WEB ? 'mt-16' : 'mt-4'
      }`}>
      <div className="text-4xl text-gray-800 flex flex-col text-center md:flex-row space-x-2">
        <span className="font-bold">Exclusive offer</span>
        <span className="font-normal">3 months at 50% off!</span>
      </div>
      <button
        disabled={showLoading}
        className="rounded-xl bg-orange-is border-transparent self-center py-3 px-4 text-white text-3xl font-bold"
        onClick={onApplyDiscount}>
        {showLoading && <LoadingSpinnerIcon color="white" className="mb-1" />} Apply Discount
      </button>
    </div>
  );
};
