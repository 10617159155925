import { getIntlCurrency, Platform, SubscriptionTier } from '@invoice-simple/common';
import {
  getCouponDiscount,
  hasDiscountForCadence,
  PaywallCouponConfig
} from '@invoice-simple/is-coupon';

import AlertModel from 'src/models/AlertModel';
import UserModel, { Cadence } from 'src/models/UserModel';
import { defaultsStore } from 'src/stores/DefaultsStore';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';

export const getPaywallCouponInfo = (): PaywallCouponConfig | undefined => {
  return UserModel.getInstance()?.paywallCouponInfo;
};

export const getSavingsText = (
  paywallCouponConfig: PaywallCouponConfig,
  tier: SubscriptionTier,
  cadence: Cadence
): string | undefined => {
  const user = UserModel.getInstance();
  const { discountType } = paywallCouponConfig;

  const discountValue =
    getCouponDiscount({ paywallCouponConfig, tier, cadence, platform: Platform.WEB }) ?? 0;

  if (discountValue <= 0) return;

  const percentageSavings = `${discountValue}%`;
  const flatSavings = getIntlCurrency({
    number: discountValue / 100,
    currencyCode: user.geoSubCurrencyCode,
    localeCode: defaultsStore.locale.name
  });

  return discountType === 'percentage' ? percentageSavings : flatSavings;
};

export const maybeAlertCoupon = (user: UserModel): void => {
  const couponQueryParam = getURLQueryParam(URLQueryParamKeys.COUPON);
  if (!couponQueryParam) return;

  if (!user.paywallCouponStatus) {
    return;
  }

  if (user.paywallCouponStatus === 'expired') {
    return AlertModel.setAlertObject({
      type: 'danger',
      titleMessageId: 'expiredPromotion'
    });
  }

  if (user.paywallCouponStatus === 'not_eligible') {
    return AlertModel.setAlertObject({
      type: 'danger',
      titleMessageId: 'notEligible'
    });
  }
};

export const hasValidDiscount = (
  paywallCouponConfig: PaywallCouponConfig,
  cadence: Cadence
): boolean => {
  const webCouponConfig = paywallCouponConfig.web;

  if (!webCouponConfig) {
    return false;
  }

  if (webCouponConfig.type === 'non-tiered') {
    return cadence === Cadence.ANNUAL ? !!webCouponConfig.annual : !!webCouponConfig.monthly;
  }

  return !!Object.values(webCouponConfig.tieredDiscounts).find(({ monthly, annual }) =>
    cadence === Cadence.ANNUAL ? !!annual : !!monthly
  );
};

export const getSubscriptionBadgeDiscount = (tier: SubscriptionTier, selectedInterval: Cadence) => {
  const user = UserModel.getInstance();
  const couponInfo = user.paywallCouponInfo;

  const hasDiscount = couponInfo && hasValidDiscount(couponInfo, selectedInterval);

  if (couponInfo && hasDiscount) {
    return getSavingsText(couponInfo, tier, selectedInterval);
  }

  if (selectedInterval !== Cadence.ANNUAL) return;

  const discountPercentage = getAnnualDiscount(tier);

  return discountPercentage;
};

export const getAnnualDiscount = (tier: SubscriptionTier) => {
  const user = UserModel.getInstance();

  const originalPrice = (user.getGeoSubAmount({ tier, cadence: Cadence.MONTHLY }) / 100) * 12;
  const finalPrice = user.getGeoSubAmount({ tier, cadence: Cadence.ANNUAL }) / 100;

  const discountPercentage = Math.floor(((originalPrice - finalPrice) / originalPrice) * 100);

  return `${discountPercentage}%`;
};

export type DiscountCadenceType = 'monthly-only' | 'annual-only' | 'monthly-annual';

export const getDiscountSupportedCadence = (
  paywallCouponConfig: PaywallCouponConfig
): DiscountCadenceType | null => {
  const webCouponConfig = paywallCouponConfig.web;

  if (!webCouponConfig) {
    return null;
  }

  const hasMonthlyDiscount = hasDiscountForCadence(webCouponConfig, Cadence.MONTHLY);
  const hasAnnualDiscount = hasDiscountForCadence(webCouponConfig, Cadence.ANNUAL);

  if (hasMonthlyDiscount && hasAnnualDiscount) {
    return 'monthly-annual';
  }

  if (hasMonthlyDiscount) {
    return 'monthly-only';
  }

  if (hasAnnualDiscount) {
    return 'annual-only';
  }

  return null;
};
