import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import UserModel from 'src/models/UserModel';

import './CancelScreens.scss';

import { Platform } from '@invoice-simple/common';

import { getFormattedDateString } from 'src/util/date';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import { ReturnToAppButton } from '../components/ReturnToAppButton';

interface Props {
  user: UserModel;
  platform: Platform;
  onError: (error: { title?: string; body?: string }) => void;
}

const CancelSuccessScreen = ({ user, platform }: Props) => {
  useEffect(() => {
    user.events.trackAction('subscription-cancel-pageViewed', {
      page: 'confirmation'
    });
  }, []);

  /* Shouldn't reach this screen if from mobile app WebView */
  if (platform !== Platform.WEB) {
    return null;
  }

  const Completed = () => {
    let subscriptionExpiry: string;

    const expiryFromQueryParam = getURLQueryParam(URLQueryParamKeys.SUB_EXPIRY_MS);
    if (expiryFromQueryParam) {
      subscriptionExpiry = getFormattedDateString(+expiryFromQueryParam);
    } else {
      const expiryFromUser = user.lastActivePaidWebSub(
        user.activeSubscriptionTier
      )?.expiryTimestamp;
      subscriptionExpiry = expiryFromUser
        ? getFormattedDateString(expiryFromUser)
        : 'the end of your current billing period';
    }

    return (
      <>
        <h1>Thank you for your business</h1>
        <p className="cancel-content">
          Your subscription has been canceled. You have access to your subscribed tier till{' '}
          {<span className="font-bold">{subscriptionExpiry}</span>}. You will also receive an email
          confirmation shortly.
        </p>
      </>
    );
  };

  return (
    <section className="text-center cancel-screen">
      <img src="/images/logo-gradient-and-title.png" />
      <Completed />
      <ReturnToAppButton user={user} platform={platform} />
    </section>
  );
};

export default observer(CancelSuccessScreen);
