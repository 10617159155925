import React from 'react';

import { Platform } from '@invoice-simple/common';

import { Widget } from '@typeform/embed-react';

import UserModel, { Cadence } from 'src/models/UserModel';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';

interface Props {
  formId: string;
  onComplete: () => void;
  user: UserModel;
  platform: Platform;
}

export const TypeForm = ({ formId, onComplete, user, platform }: Props) => {
  const currentSub = user.lastActivePaidWebSub(user.activeSubscriptionTier);
  const userId = getURLQueryParam(URLQueryParamKeys.USER_ID) ?? user.id ?? '';
  const email = getURLQueryParam(URLQueryParamKeys.EMAIL) ?? user.email ?? '';
  const usdPrice =
    getURLQueryParam(URLQueryParamKeys.USD_PRICE) ??
    user
      .getGeoSubAmount({
        cadence: currentSub?.planInterval as Cadence,
        tier: currentSub?.tier,
        usdPrice: true
      })
      .toString();
  const hasAnnualSub = getURLQueryParam(URLQueryParamKeys.ANNUAL_SUB) ?? user.hasAnnualSub;
  const userCreatedAt =
    getURLQueryParam(URLQueryParamKeys.USER_CREATED_AT) ??
    user.createdAt?.getTime().toString() ??
    '0';
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Widget
        id={formId}
        disableTracking={process.env.REACT_APP_ENV !== 'production'}
        enableFullscreen={false}
        style={{ width: '100%', height: '100%' }}
        autoResize
        autoFocus
        onSubmit={onComplete}
        inlineOnMobile
        source={process.env.REACT_APP_URL}
        hidden={{
          environment: process.env.REACT_APP_ENV ?? 'development',
          email: email,
          created_at: userCreatedAt,
          annual: hasAnnualSub ? 'yes' : 'no',
          platform,
          usd_price: usdPrice,
          uid: userId ?? ''
        }}
      />
    </div>
  );
};
