const getRedditTag = (): Function | undefined => {
  type WindowWithDataLayer = Window & {
    rdt?: Function;
  };

  return (window as unknown as WindowWithDataLayer)?.rdt;
};

export const trackRedditPurchase = () => {
  const rdt = getRedditTag();

  rdt?.('init', 't2_95mtjksx');
  rdt?.('track', 'Purchase');
};
