import { defineMessages } from 'src/i18n';

const newPaywallMessages = {
  invoicesPerMonth: {
    id: 'subscription.tiered.invoicesPerMonth',
    defaultMessage: 'Invoices per month'
  },
  estimatesPerMonth: {
    id: 'subscription.tiered.estimatesPerMonth',
    defaultMessage: 'Estimates per month'
  },
  invoiceMonthlyLimitEssentials: {
    id: 'subscription.tiered.invoiceMonthlyLimit',
    defaultMessage: 'Up to {limit}'
  },
  paywallTitle: {
    id: 'subscription.tiered.paywallTitle',
    defaultMessage: "You're on a roll!"
  },
  paywallTitleUpgrade: {
    id: 'subscription.tiered.paywallTitleUpgrade',
    defaultMessage: 'Upgrade to {plan}'
  },
  paywallSubtitle: {
    id: 'subscription.tiered.paywallSubtitle',
    defaultMessage:
      'Upgrade now to continue sending neat, tidy and professional invoices on-the-spot. You’ll be able to...'
  },
  paywallSubtitleUpgrade: {
    id: 'subscription.tiered.paywallSubtitleUpgrade',
    defaultMessage: 'Optimize your business with all of the features below.'
  },
  paywallValueProp1: {
    id: 'subscription.tiered.paywallValueProp1',
    defaultMessage: 'Easily bill customers with professional-looking invoices and estimates'
  },
  paywallValueProp2: {
    id: 'subscription.tiered.paywallValueProp2',
    defaultMessage: 'Keep track of who has paid with simple reporting and notifications'
  },
  paywallValueProp3: {
    id: 'subscription.tiered.paywallValueProp3',
    defaultMessage: 'Send invoices and estimates on the fly with always-on-the-go mobile access'
  },
  paywallValueProp4: {
    id: 'subscription.tiered.paywallValueProp4',
    defaultMessage: 'Have full control of invoices with customized formatting and design'
  },
  paywallValuePropUnlimitedInvoices: {
    id: 'subscription.tiered.paywallValuePropUnlimitedInvoices',
    defaultMessage: 'Generate as many invoices as you need each month for your business'
  },
  paywallValuePropUpToTenInvoices: {
    id: 'subscription.tiered.paywallValuePropUpToTenInvoices',
    defaultMessage: 'Generate up to 10 invoices and unlimited estimates every month'
  },
  paywallValuePropAddPhotos: {
    id: 'subscription.tiered.paywallValuePropAddPhotos',
    defaultMessage: 'Enhance invoices with photos for a personalized touch'
  },
  paywallValuePropSummaryReports: {
    id: 'subscription.tiered.paywallValuePropSummaryReports',
    defaultMessage: 'Access monthly and annual breakdowns of sales, clients, and items'
  },
  paywallValuePropPremiumTemplates: {
    id: 'subscription.tiered.paywallValuePropPremiumTemplates',
    defaultMessage: 'Unlock professionally curated premium templates that impress customers'
  },
  paywallValuePropCustomizeWithTemplates: {
    id: 'subscription.tiered.paywallValuePropCustomizeWithTemplates',
    defaultMessage:
      'Customize your invoices by selecting one of our professionally crafted templates'
  },
  paywallValuePropBusinessOverSignature: {
    id: 'subscription.tiered.paywallValuePropBusinessOverSignature',
    defaultMessage: 'Build trust with clients by adding your own digital signature to invoices'
  },
  paywallValuePropAutofill: {
    id: 'subscription.tiered.paywallValuePropAutofill',
    defaultMessage: 'Streamline your workflow and save time with client and item autofill'
  },
  paywallValuePropDueDateReminders: {
    id: 'subscription.tiered.paywallValuePropDueDateReminders',
    defaultMessage: 'Stay on top of payments with automatic invoice due date reminders'
  },
  paywallValuePropSignatures: {
    id: 'subscription.tiered.paywallValuePropSignatures',
    defaultMessage: 'Streamline communications by having clients sign estimates from their phones'
  },
  paywallValuePropCustomerRatings: {
    id: 'subscription.tiered.paywallValuePropCustomerRatings',
    defaultMessage: 'Secure valuable client ratings by including a review link'
  },
  paywallValuePropCustomerSupport: {
    id: 'subscription.tiered.paywallValuePropCustomerSupport',
    defaultMessage: 'Receive prioritized support dedicated to ensuring your success'
  },
  paywallValuePropReceiptScanner: {
    id: 'subscription.tiered.paywallValuePropReceiptScanner',
    defaultMessage: 'Scan any receipt and Invoice Simple captures the key info automatically'
  },
  testimonialsTitle: {
    id: 'subscription.tiered.testimonialsTitle',
    defaultMessage: 'Join the 500,000+ small businesses sending invoices in three minutes or less'
  },
  testimonials1Name: {
    id: 'subscription.tiered.testimonials1Name',
    defaultMessage: 'Juan, Construction'
  },
  testimonials1Message: {
    id: 'subscription.tiered.testimonials1Message',
    defaultMessage: "Worth every penny. I've been using Invoice Simple for over a year now."
  },
  testimonials2Name: {
    id: 'subscription.tiered.testimonials2Name',
    defaultMessage: 'Lisa, Freelance'
  },
  testimonials2Message: {
    id: 'subscription.tiered.testimonials2Message',
    defaultMessage: 'I can produce an invoice to a client in minutes. Technology at its best."'
  },
  testimonials3Name: {
    id: 'subscription.tiered.testimonials3Name',
    defaultMessage: 'Mike, Painter'
  },
  testimonials3Message: {
    id: 'subscription.tiered.testimonials3Message',
    defaultMessage:
      'It’s a tool for those serious about making a good impression and being professional.'
  },
  tableTopTitle: {
    id: 'subscription.tiered.tableTopTitle',
    defaultMessage: 'Exactly what you need. Nothing more, nothing less.'
  },
  tableTopSubtitle: {
    id: 'subscription.tiered.tableTopSubtitle',
    defaultMessage: 'Cancel or change your plan anytime.'
  },
  freeTrialTableTopTitle: {
    id: 'subscription.tiered.freeTrialTableTopTitle',
    defaultMessage: 'Your free trial has ended'
  },
  freeTrialTableTopSubtitle: {
    id: 'subscription.tiered.freeTrialtableTopSubtitle',
    defaultMessage: 'Subscribe to continue using Invoice Simple'
  },
  comingSoon: {
    id: 'subscription.tiered.comingSoon',
    defaultMessage: 'Coming soon'
  },
  featureCollaboration: {
    id: 'subscription.tiered.featureCollaboration',
    defaultMessage: 'Collaboration'
  },
  featureAnyDevice: {
    id: 'subscription.tiered.anyDevice',
    defaultMessage: 'Any device, any app (iOS, Android, Web)'
  },
  featurePayments: {
    id: 'subscription.tiered.featurePayments',
    defaultMessage: 'Online Payments'
  },
  featurePassTransactionFee: {
    id: 'subscription.tiered.featurePassTransactionFee',
    defaultMessage: 'Passing payment transaction fees'
  },
  featureClientSignature: {
    id: 'subscription.tiered.featureClientSignature',
    defaultMessage: 'Request clients to sign estimates'
  },
  featureQR: {
    id: 'subscription.tiered.featureQR',
    defaultMessage: 'QR code payments'
  },
  featureDeposit: {
    id: 'subscription.tiered.featureDeposit',
    defaultMessage: 'Deposit payments'
  },
  featureAutofill: {
    id: 'subscription.tiered.featureAutofill',
    defaultMessage: 'Autofill clients and items'
  },
  featureViews: {
    id: 'subscription.tiered.featureViews',
    defaultMessage: 'Views & Reporting'
  },
  featureRealTime: {
    id: 'subscription.tiered.featureRealTime',
    defaultMessage: 'Real-time status updates'
  },
  featurePremiumTemplates: {
    id: 'subscription.tiered.featurePremiumTemplates',
    defaultMessage: 'Premium templates'
  },
  featureInvoicing: {
    id: 'subscription.tiered.featureInvoicing',
    defaultMessage: 'Invoicing'
  },
  featureSummaryReports: {
    id: 'subscription.tiered.featureSummaryReports',
    defaultMessage: 'Business summary reports'
  },
  featureInvoiceSignature: {
    id: 'subscription.tiered.featureInvoiceSignature',
    defaultMessage: 'Signature'
  },
  featureDueDateReminders: {
    id: 'subscription.tiered.featureDueDateReminders',
    defaultMessage: 'Due date reminders'
  },
  featurePhotos: {
    id: 'subscription.tiered.featurePhotos',
    defaultMessage: 'Add photos'
  },
  featureRatings: {
    id: 'subscription.tiered.featureRatings',
    defaultMessage: 'Ratings & reviews'
  },
  featureSupport: {
    id: 'subscription.tiered.featureSupport',
    defaultMessage: 'Support'
  },
  featureMessageSupport: {
    id: 'subscription.tiered.featureMessageSupport',
    defaultMessage: 'Email & chat support'
  },
  featurePrioritySupport: {
    id: 'subscription.tiered.featurePrioritySupport',
    defaultMessage: 'Priority queue'
  },
  unlimited: {
    id: 'subscription.tiered.unlimited',
    defaultMessage: 'Unlimited'
  },
  activeMobileSub: {
    id: 'subscription.tiered.activeMobileSub',
    defaultMessage: 'Your subscription is managed through the {store}.'
  },
  activeMobileSubMessage: {
    id: 'subscription.tiered.activeMobileSubMessage',
    defaultMessage: 'Please follow the link we sent to your mobile device to complete your upgrade.'
  },
  mostPopular: {
    id: 'subscription.tiered.mostPopular',
    defaultMessage: 'Most popular'
  }
};

export const discountedPaywallMessages = {
  discountedPayWallTitle: {
    id: 'subscription.tiered.discountedPayWallTitle',
    defaultMessage: 'Choose a Plan Now. Get {discount}% Off!'
  },
  discountedPayWallTitleTiered: {
    id: 'subscription.tiered.discountedPayWallTitleTiered',
    defaultMessage:
      'Choose a Plan Now. Choose a Plan Now. Get up to {discount}% Off for {duration} months!'
  },
  discountedPayWallSubTitle: {
    id: 'subscription.tiered.discountedPayWallSubTitle',
    defaultMessage: 'Send professional invoices on-the-spot. You’ll be able to:'
  },
  discountedPaywallValueProp1: {
    id: 'subscription.tiered.discountedPaywallValueProp1',
    defaultMessage: 'Easily quote and bill customers with invoices and estimates'
  },
  discountedPaywallValueProp2: {
    id: 'subscription.tiered.discountedPaywallValueProp2',
    defaultMessage: 'Keep track of who has paid with simple notifications and reports'
  },
  discountedPaywallValueProp3: {
    id: 'subscription.tiered.discountedPaywallValueProp3',
    defaultMessage: 'Send from your computer or on-the-go with our mobile app'
  },
  discountedPaywallValueProp4: {
    id: 'subscription.tiered.discountedPaywallValueProp4',
    defaultMessage: 'Have full control of invoices with customized template designs'
  },
  discountedPaywallJoinInvoiceSimple: {
    id: 'subscription.tiered.discountedPaywallJoinInvoiceSimple',
    defaultMessage:
      'Join the 500,000+ small businesses sending invoices. We have 4.85 stars for a reason.'
  },
  discountedPaywallTableTopTitle: {
    id: 'subscription.tiered.discountedPaywallTableTopTitle',
    defaultMessage: '{discount}% off for {duration} months'
  },
  discountedPaywallTableTopTitleCadence: {
    id: 'subscription.tiered.discountedPaywallTableTopTitleCadence',
    defaultMessage: '{discount}% off for {duration} months on {cadence} plans'
  },
  discountedPaywallTableTopTitleTiered: {
    id: 'subscription.tiered.discountedPaywallTableTopTitleTiered',
    defaultMessage: 'Up to {discount}% off for {duration} months'
  },
  discountedPaywallTableTopSubtitle: {
    id: 'subscription.tiered.discountedPaywallTableTopSubtitle',
    defaultMessage:
      'Plans will renew at regular price after {duration} months. Cancel or change your plan anytime.'
  },
  discountedPaywallSupportHelp: {
    id: 'subscription.tiered.discountedPaywallSupportHelp',
    defaultMessage: 'If you need help deciding which plan is best for you'
  },
  discountedPaywallContactSupport: {
    id: 'subscription.tiered.discountedPaywallContactSupport',
    defaultMessage: 'Contact Support'
  },
  discountedPaywallBuyNow: {
    id: 'subscription.tiered.discountedPaywallBuyNow',
    defaultMessage: 'Buy Now'
  },
  discountedPaywallInvoiceMonthlyLimit: {
    id: 'subscription.tiered.discountedPaywallInvoiceMonthlyLimit',
    defaultMessage: '{limit} per month'
  },
  discountedPaywallUnlimited: {
    id: 'subscription.tiered.discountedPaywallUnlimited',
    defaultMessage: 'Unlimited'
  },
  discountedPaywallBadge: {
    id: 'subscription.tiered.discountedPaywallBadge',
    defaultMessage: 'Save {discount}'
  }
};

export const premiumLegacyPaywallMessages = {
  cardBodyCurrentPlan: {
    id: 'premiumLegacy.paywall.cardBodyCurrentPlan',
    defaultMessage: 'Everything in your current plan'
  },
  cardBodyPlus: {
    id: 'premiumLegacy.paywall.plus',
    defaultMessage: 'plus'
  },
  clientSignatures: {
    id: 'premiumLegacy.paywall.clientSignatures',
    defaultMessage: 'Client Signatures'
  },
  requestReviews: {
    id: 'premiumLegacy.paywall.requestReviews',
    defaultMessage: 'Request Reviews'
  },
  deposits: {
    id: 'premiumLegacy.paywall.deposits',
    defaultMessage: 'Deposits'
  },
  paywallValuePropDeposits: {
    id: 'premiumLegacy.paywall.paywallValuePropDeposits',
    defaultMessage: 'Enhance your cashflow by requesting deposit payments upfront from clients'
  },
  switchSuccessAlertMessagePremiumLegacy: {
    id: 'premiumLegacy.paywall.alert.message.switchSuccessAlertMessagePremiumLegacy',
    defaultMessage:
      'You can now access Client Signatures, Premium Templates, Request Reviews, and Deposits.'
  },
  paywallValuePropVersionHistory: {
    id: 'premiumLegacy.paywall.paywallValuePropVersionHistory',
    defaultMessage: 'Never lose a change with access to previous versions of all your invoices'
  }
};

export const subscriptionTieredV3Messages = {
  unlimitedInvoices: {
    id: 'subscription.free.benefits.invoices',
    defaultMessage: 'unlimited invoices'
  },
  invoicesPerMonthAmount: {
    id: 'subscription.tiered.invoicesPerMonthAmount',
    defaultMessage: '{invoices} invoices per month'
  },
  clientSignatures: {
    id: 'settings.list.clientSignatures',
    defaultMessage: 'client signatures'
  },
  businessOwnerSignature: {
    id: 'subscription.tiered.businessOwnerSignature',
    defaultMessage: 'Business owner signature'
  },
  businessSummaryReports: {
    id: 'subscription.tiered.businessSummaryReports',
    defaultMessage: 'Business summary reports'
  },
  onlineCollaboration: {
    id: 'subscription.tiered.onlineCollaboration',
    defaultMessage: 'Online collaboration'
  },
  creditCardProcessing: {
    id: 'subscription.tiered.creditCardProcessing',
    defaultMessage: 'Credit card processing'
  },
  acceptDeposits: {
    id: 'subscription.tiered.acceptDeposits',
    defaultMessage: 'Accept deposits'
  },
  planTierCadence: {
    id: 'subscription.tiered.planTierCadence',
    defaultMessage: '{tier} {cadence} plan'
  },
  subscriptionBuyNowButton: {
    id: 'subscription.tiered.subscriptionBuyNowButton',
    defaultMessage: 'Buy now'
  },
  premiumPlanDesc: {
    id: 'subscription.tiered.premiumPlanDesc',
    defaultMessage: 'Grow with unlimited invoicing and priority support'
  },
  plusPlanDesc: {
    id: 'subscription.tiered.plusPlanDesc',
    defaultMessage: 'Add customization, reporting and automation'
  },
  essentialsPlanDesc: {
    id: 'subscription.tiered.essentialsPlanDesc',
    defaultMessage: 'Easily send invoices, receipts and receive payments'
  },
  everythingInTier: {
    id: 'subscription.tiered.everythingInTier',
    defaultMessage: 'Everything in {tier}, and'
  },
  seeFeatures: {
    id: 'subscription.tiered.seeFeatures',
    defaultMessage: 'See features'
  },
  hideFeatures: {
    id: 'subscription.tiered.hideFeatures',
    defaultMessage: 'Hide features'
  },
  compareAllPlans: {
    id: 'subscription.tiered.compareAllPlans',
    defaultMessage: 'Compare all plans'
  },
  billedMonthly: {
    id: 'subscription.tiered.billedMonthly',
    defaultMessage: 'Billed {price} monthly for {duration} months'
  },
  billedAnnually: {
    id: 'subscription.tiered.billedAnnually',
    defaultMessage: 'Billed {price} for the first year'
  },
  priceAfterDiscount: {
    id: 'subscription.tiered.priceAfterDiscount',
    defaultMessage: '{price} per {cadence} after'
  },
  subscribeHeader: {
    id: 'subscription.tiered.subscribeHeader',
    defaultMessage: 'Subscribe'
  },
  compareAllFeatures: {
    id: 'subscription.tiered.compareAllFeatures',
    defaultMessage: 'Compare all features'
  },
  badgeUpToDiscount: {
    id: 'subscription.tiered.badgeUpToDiscount',
    defaultMessage: 'Up to {discount}% off'
  },
  discountBannerTitle: {
    id: 'subscription.tiered.discountBannerTitle',
    defaultMessage: 'Get up to {discount}% off your first {duration} months'
  },
  discountBannerTitleYear: {
    id: 'subscription.tiered.discountBannerTitleYear',
    defaultMessage: 'Get up to {discount}% off your first year'
  },
  discountBannerMessage: {
    id: 'subscription.tiered.discountBannerMessage',
    defaultMessage: 'Plans renew at regular price after'
  }
};

export const messages = defineMessages({
  ...newPaywallMessages,
  ...discountedPaywallMessages,
  ...premiumLegacyPaywallMessages,
  ...subscriptionTieredV3Messages,

  titleReachedLimit: {
    id: 'subscription.tiered.reachedLimitTitle',
    defaultMessage: "You've used your {freeQuota} free invoice & estimate limit."
  },
  subtitleReachedLimit: {
    id: 'subscription.tiered.reachedLimitSubtitle',
    defaultMessage: 'Upgrade now for more.'
  },
  subtitle: {
    id: 'subscription.tiered.subtitle',
    defaultMessage: 'Thanks for trying Invoice Simple. Choose your plan and get invoicing.'
  },
  upgrade: {
    id: 'subscription.tiered.upgrade',
    defaultMessage: 'Upgrade Now'
  },
  switch: {
    id: 'subscription.tiered.switch',
    defaultMessage: 'Switch Now'
  },
  titleInvoiceLimitReached: {
    id: 'subscription.tiered.starterReachedLimitTitle',
    defaultMessage: "You've used your {starterQuota} monthly invoices."
  },
  subtitleInvoiceLimitReached: {
    id: 'subscription.tiered.starterReachedLimitSubtitle',
    defaultMessage: 'To continue invoicing, please upgrade.'
  },
  switchSuccessAlertTitleAnnual: {
    id: 'subscription.tiered.alert.title.switchSuccessAnnual',
    defaultMessage: 'Switched to annual {tier}!'
  },
  switchSuccessAlertTitleMonthly: {
    id: 'subscription.tiered.alert.title.switchSuccessMonthly',
    defaultMessage: 'Switched to monthly {tier}!'
  },
  switchSuccessAlertMessageStarter: {
    id: 'subscription.tiered.alert.message.switchSuccessStarter',
    defaultMessage: 'You can now create {docLimit} invoices/estimates per month.'
  },
  switchSuccessAlertMessageUnlimited: {
    id: 'subscription.tiered.alert.message.switchSuccessUnlimited',
    defaultMessage: 'You can now create unlimited invoices and estimates.'
  },
  unableToSwitchTitle: {
    id: 'subscription.tiered.alert.unableToSwitchTitle',
    defaultMessage: 'Unable to change your plan'
  },
  unableToDowngradeMessage: {
    id: 'subscription.tiered.alert.unableToDowngradeMessage',
    defaultMessage: 'Please contact support for downgrades.'
  },
  unableToCrossgradeMessage: {
    id: 'subscription.tiered.alert.unableToCrossgradeMessage',
    defaultMessage: 'Please contact support for crossgrades.'
  },
  annualTierSubscriptionTitle: {
    id: 'subscription.title.annual.tier',
    defaultMessage: 'Annual Web {tier} Subscription'
  },
  monthlyTierSubscriptionTitle: {
    id: 'subscription.title.monthly.tier',
    defaultMessage: 'Monthly Web {tier} Subscription'
  },
  scheduledSwitchNoticeAnnual: {
    id: 'subscription.tiered.alert.message.scheduledSwitchNoticeAnnual',
    defaultMessage:
      'You will be switched to the annual {tier} plan at the end of your current billing cycle on {date}.'
  },
  scheduledSwitchNoticeMonthly: {
    id: 'subscription.tiered.alert.message.scheduledSwitchNoticeMonthly',
    defaultMessage:
      'You will be switched to the monthly {tier} plan at the end of your current billing cycle on {date}.'
  },
  confirmPrompt: {
    id: 'subscription.tiered.prompt.confirm',
    defaultMessage: 'Are you sure you want to switch?'
  },
  annual: {
    id: 'plan.interval.annual',
    defaultMessage: 'Annual'
  },
  monthly: {
    id: 'plan.interval.monthly',
    defaultMessage: 'Monthly'
  },
  starterDocLimit: {
    id: 'subscription.tiered.starterDocLimit',
    defaultMessage: '{starterQuota} invoices/estimates per month'
  },
  proDocLimit: {
    id: 'subscription.tiered.proDocLimit',
    defaultMessage: 'Unlimited invoices & estimates'
  },
  support: {
    id: 'subscription.tiered.support',
    defaultMessage: '24/7 support'
  },
  cancelAnytime: {
    id: 'subscription.free.benefits.cancel',
    defaultMessage: 'Cancel anytime'
  },
  accessToMobileApps: {
    id: 'subscription.tiered.benefits.accessToMobileApps',
    defaultMessage: 'Access to mobile apps'
  },
  perYear: {
    id: 'per.year',
    defaultMessage: '/yr'
  },
  perMonth: {
    id: 'per.month',
    defaultMessage: '/mo'
  },
  tieredPlans: {
    id: 'tiered.plans',
    defaultMessage: 'Plans'
  },
  tieredFeatures: {
    id: 'tiered.features',
    defaultMessage: 'Features'
  },
  tieredPricing: {
    id: 'tiered.pricing',
    defaultMessage: 'Pricing'
  },
  invoicesOrEstimates: {
    id: 'subscription.invoicesOrEstimates',
    defaultMessage: 'Invoice/estimates'
  },
  currentActivePlan: {
    id: 'subscription.current.plan',
    defaultMessage: 'Current plan'
  },
  tieredStarterDocLimit: {
    id: 'tiered.starterDocLimit',
    defaultMessage: 'Up to {starterQuota} per month'
  },
  unlimitedDocLimit: {
    id: 'tiered.unlimited.docLimit',
    defaultMessage: 'Unlimited'
  },
  photosFeatureUnavailable: {
    id: 'app.alert.photosFeatureUnavailable',
    defaultMessage: 'Photos feature is unavailable in Starter plan'
  },
  photosFeatureUpgrade: {
    id: 'app.alert.photosFeatureUpgrade',
    defaultMessage: 'Upgrade to Pro or Premium plan to add photos.'
  },
  advancedReporting: {
    id: 'subscription.premium.feature.1',
    defaultMessage: 'Advanced reporting'
  },
  thankYouFreeTrial: {
    id: 'subscription.tiered.thankYouFreeTrial',
    defaultMessage: 'Thank you for using the free trial for Invoice Simple'
  },
  thankYouFreeTrialCreateMessage: {
    id: 'subscription.tiered.thankYouFreeTrialMessage',
    defaultMessage: 'To continue creating invoices, subscribe to one of our plans'
  },
  thankYouFreeTrialSendMessage: {
    id: 'subscription.tiered.thankYouFreeTrialSendMessage',
    defaultMessage: 'To continue sending invoices, subscribe to one of our plans'
  },
  thankYouFreeTrialOptionsMessage: {
    id: 'subscription.tiered.thankYouFreeTrialOptionsMessage',
    defaultMessage: 'To continue using invoices, subscribe to one of our plans'
  },
  clientSignatureFeatureGateTitle: {
    id: 'subscription.tiered.clientSignatureFeatureGateTitle',
    defaultMessage: 'Upgrade to Premium tier to unlock Client Signatures.'
  },
  clientSignatureFeatureGateBody: {
    id: 'subscription.tiered.clientSignatureFeatureGateBody',
    defaultMessage: 'Request estimates to be signed by your clients.'
  },
  premiumTemplatesFeatureGateTitle: {
    id: 'subscription.tiered.premiumTemplatesFeatureGateTitle',
    defaultMessage: 'Upgrade to Premium tier to unlock Premium Templates.'
  },
  premiumTemplatesFeatureGateBody: {
    id: 'subscription.tiered.premiumTemplatesFeatureGateBody',
    defaultMessage: 'Access professionally curated premium templates that impress customers.'
  },
  versionHistoryFeatureGateTitle: {
    id: 'subscription.tiered.versionHistoryFeatureGateTitle',
    defaultMessage: 'Upgrade to Premium tier to unlock Version History.'
  },
  versionHistoryFeatureGateBody: {
    id: 'subscription.tiered.versionHistoryFeatureGateBody',
    defaultMessage: 'View and restore previous versions of your documents.'
  },
  featureGatePhotosTitle: {
    id: 'subscription.tiered.featureGatePhotosTitle',
    defaultMessage: 'Please upgrade your plan to add photos.'
  },
  depositsFeatureGateTitle: {
    id: 'subscription.tiered.depositsFeatureGateTitle',
    defaultMessage: 'Upgrade to Premium tier to unlock deposits'
  },
  depositsFeatureGateBody: {
    id: 'subscription.tiered.depositsFeatureGateBody',
    defaultMessage: 'Request a percentage or fixed-amount deposit on your invoice.'
  },
  expensesFeatureUpgrade: {
    id: 'subscription.tiered.expensesFeatureUpgrade',
    defaultMessage: 'Upgrade to unlock the Easy Expense Tracker.'
  },
  reportsFeatureUpgrade: {
    id: 'subscription.tiered.reportsFeatureUpgrade',
    defaultMessage: 'Please upgrade your plan to unlock reporting.'
  },
  signatureFeatureUpgrade: {
    id: 'subscription.tiered.signatureFeatureUpgrade',
    defaultMessage: 'Please upgrade your plan to add signatures.'
  },
  requestReviewFeatureUpgrade: {
    id: 'subscription.tiered.requestReviewFeatureUpgrade',
    defaultMessage: 'Please upgrade your plan to add review links.'
  },
  dueDateReminderFeatureUpgrade: {
    id: 'subscription.tiered.dueDateReminderFeatureUpgrade',
    defaultMessage: 'Please upgrade your plan to use reminders.'
  },
  accessMoreFeatures: {
    id: 'subscription.tiered.accessMoreFeatures',
    defaultMessage: 'Choose the best plan to access more features.'
  },
  downgradeConfirmMessage: {
    id: 'subscription.tiered.downgradeConfirmMessage',
    defaultMessage: 'Downgrading from {oldTier} plan to {newTier} plan will limit your access.'
  },
  switchedAtEndofCycle: {
    id: 'subscription.tiered.switchedAtEndofCycle',
    defaultMessage:
      'You will be switched to your new plan at the end of your current billing cycle on {date}.'
  },
  subscriptionCouponDesc: {
    id: 'subscription.coupon.monthly.desc',
    defaultMessage: 'Then {price}{cadence}.'
  },
  subscriptionDiscountDurationMonthly: {
    id: 'subscription.discount.duration.monthly',
    defaultMessage: 'For {months} months.'
  },
  subscriptionDiscountDurationMonth: {
    id: 'subscription.discount.duration.month',
    defaultMessage: 'For 1 month.'
  },
  versionHistory: {
    id: 'subscription.tiered.versionHistory',
    defaultMessage: 'Version history'
  }
});
