import React, { useContext } from 'react';

import { useFlags } from 'flagsmith/react';

import { Platform, SubscriptionCadence, SubscriptionTier } from '@invoice-simple/common';
import {
  getCouponSku as getIsCouponSku,
  isSubStatusEligible,
  PaywallCouponConfig,
  paywallCoupons
} from '@invoice-simple/is-coupon';

import { SubscriptionStatus } from 'src/apis/subscriptionsAPI';
import UserModel from 'src/models/UserModel';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';

export interface CancelPromoContextValue {
  cancelPromoEnabled: boolean;
  couponSku: string | null;
  currentSku: string;
  platform: Platform;
}

interface Props {
  user: UserModel;
  children: React.ReactNode;
}

const CancelPromoContext = React.createContext<CancelPromoContextValue | null>(null);

export const useCancelPromo = (): CancelPromoContextValue => {
  const context = useContext(CancelPromoContext);
  if (!context) {
    throw new Error('useCancelPromo must be used within a CancelPromoProvider');
  }
  return context;
};

export const CancelPromoProvider = (props: Props) => {
  const { cancel_promo } = useFlags(['cancel_promo']);
  const hasAnnualSub =
    getURLQueryParam(URLQueryParamKeys.ANNUAL_SUB) === 'true' || props.user.hasAnnualSub;

  /**
   * The mobile cancellation page is public so we have to rely on params to determine eligibility
   */
  const isMobileCancellation = window.location.href.includes('/subscription-cancel-v2-mobile');
  const currentSku =
    getURLQueryParam(URLQueryParamKeys.CURRENT_SKU) || props.user.subscriptionOrderSku || '';
  const currentTier = (getURLQueryParam(URLQueryParamKeys.CURRENT_TIER) || '') as SubscriptionTier;
  const promoEnabledMobile = !!(
    isMobileCancellation &&
    getURLQueryParam(URLQueryParamKeys.PROMO_ENABLED) === 'true' &&
    currentSku &&
    currentTier &&
    !hasAnnualSub
  );

  const platform = (getURLQueryParam(URLQueryParamKeys.PLATFORM) as Platform) || Platform.WEB;
  const subscriptionStatus: Pick<
    SubscriptionStatus,
    'active' | 'hasPaidSub' | 'hadPaidSubsInThePast' | 'orderSku' | 'hasFreeSub'
  > = {
    active: props.user.isSubActive,
    hasPaidSub: props.user.hasPaidSub,
    hadPaidSubsInThePast: props.user.hadPaidSubsInThePast,
    hasFreeSub: props.user.hasFreeTrialSubscription,
    orderSku: props.user.subscriptionOrderSku as string
  };
  const subStatusEligible = isSubStatusEligible({
    paywallCouponConfig: paywallCoupons.cancelPromo50off3months,
    subscriptionStatus,
    platform
  });
  const promoEnabledWeb = cancel_promo.enabled && subStatusEligible && !hasAnnualSub;

  const cancelPromoEnabled = promoEnabledWeb || promoEnabledMobile;

  return (
    <CancelPromoContext.Provider
      value={{
        cancelPromoEnabled,
        couponSku: cancelPromoEnabled ? getCouponSku({ platform, currentTier }) : null,
        currentSku,
        platform
      }}>
      {props.children}
    </CancelPromoContext.Provider>
  );
};

const getCouponSku = ({
  platform,
  currentTier
}: {
  platform: Platform;
  currentTier: SubscriptionTier;
}) => {
  const getCouponSkuParams: {
    paywallCouponConfig: PaywallCouponConfig;
    cadence: SubscriptionCadence;
    currentSku: string | null;
  } = {
    paywallCouponConfig: paywallCoupons.cancelPromo50off3months,
    cadence: 'monthly',
    currentSku: null
  };

  return getIsCouponSku({
    ...getCouponSkuParams,
    platform,
    tier: platform !== Platform.WEB ? currentTier : undefined
  });
};
