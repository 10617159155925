import { GenericEventMeta, MobileEventName } from '@invoice-simple/is-events';

/**
 * Uses the postMessage() API injected by the webview to send events back to the mobile app
 */
export const trackMobileWebviewEvent = <T extends MobileEventName>({
  event,
  meta
}: {
  event: T;
  meta?: GenericEventMeta<T>;
}) => {
  const webview = window?.['ReactNativeWebView'];
  webview && webview.postMessage(JSON.stringify({ event, meta }));
};
