import differenceInDays from 'date-fns/differenceInDays';
import { default as parseDate } from 'date-fns/parse';
import { computed, observable } from 'mobx';

import { SubscriptionTier } from '@invoice-simple/common';

import { ApiSubscription } from '../types/ApiSubscription';

export const WEB_PREMIUM_TIER_SKU_REGEX = /com\.invoicesimple\.web-mobile\.((monthly)|(annual))\./;
export const PAYMENTS_TIER_SKU_REGEX = /com\.invoicesimple\.web\.payments\.free-tier/;
export default class Subscription {
  @observable accountId: string;
  @observable autoRenewing: boolean | null;
  @observable expiryTimestamp: number | null; // 1514923012808
  @observable orderTimestamp: number; // 1513713412808
  @observable isActive: boolean;
  @observable isGracePeriod: boolean;
  @observable isUnlimited: boolean;
  @observable orderId: string; // is.O44IufQ1Tm-rXC7u9SbP6w
  @observable orderSku: string; // com.invoicesimple.freetrial.web.14day / com.invoicesimple.web.monthly1.usd
  @observable planInterval: string | null; // week
  @observable isTrial: boolean;

  @computed
  get isPaid(): boolean {
    return !this.isTrial && this.isActive;
  }
  @computed
  get isTrialActive(): boolean {
    return this.isTrial && this.isActive;
  }
  @computed
  get isWebPaymentsTier(): boolean {
    return !!this.orderSku && PAYMENTS_TIER_SKU_REGEX.test(this.orderSku);
  }
  @computed
  get isWebStarterTier(): boolean {
    return !!this.orderSku && /web\S*starter.tier/.test(this.orderSku);
  }
  @computed
  get isWebProTier(): boolean {
    return !!this.orderSku && /web\S*pro.tier/.test(this.orderSku);
  }
  @computed
  get isOldWebPremiumTier(): boolean {
    return (
      !!this.orderSku &&
      WEB_PREMIUM_TIER_SKU_REGEX.test(this.orderSku) &&
      /**
       * new SKUs all have web-mobile in them: https://app.asana.com/0/1200380663730997/1204091625088940/f
       * adding extra check here to ensure this doesn't return true for all new SKUs
       */
      !(this.isEssentialsTier || this.isPlusTier)
    );
  }
  @computed
  get isEssentialsTier(): boolean {
    return !!this.orderSku && this.orderSku.includes('essentials');
  }
  @computed
  get isPlusTier(): boolean {
    return !!this.orderSku && this.orderSku.includes('plus');
  }
  @computed
  get isNewPremiumTier(): boolean {
    return (
      !!this.orderSku && this.orderSku.includes('premium') && !this.orderSku.includes('legacy')
    );
  }
  @computed
  get isNewWebPremiumTier(): boolean {
    return !!this.orderSku && this.orderSku.includes('web') && this.orderSku.includes('premium');
  }
  @computed
  get isPremiumLegacyTier(): boolean {
    return (
      !!this.orderSku && this.orderSku.includes('web') && this.orderSku.includes('premium.legacy')
    );
  }
  @computed
  get isPremiumTier(): boolean {
    return !!this.orderSku && (this.isOldWebPremiumTier || this.isNewPremiumTier);
  }
  @computed
  get isWebMobile(): boolean {
    return !!(this.orderSku && this.orderSku.match(/web-mobile/));
  }
  @computed
  get isMobileOnly(): boolean {
    return !!(this.orderSku && this.orderSku.match(/logicalengine.qsei|aadhk.woinvoice/));
  }
  @computed
  get isAndroid(): boolean {
    return !!(this.orderSku && this.orderSku.match(/aadhk.woinvoice/));
  }
  @computed
  get isiOS(): boolean {
    return !!(this.orderSku && this.orderSku.match(/logicalengine.qsei/));
  }

  @computed
  get tier(): SubscriptionTier | null {
    switch (true) {
      case this.isPremiumLegacyTier:
        return SubscriptionTier.PREMIUM_LEGACY;
      case this.isPremiumTier:
        return SubscriptionTier.PREMIUM;
      case this.isWebPaymentsTier:
        return SubscriptionTier.PAYMENTS_TIER;
      case this.isWebProTier:
        return SubscriptionTier.PRO;
      case this.isPlusTier:
        return SubscriptionTier.PLUS;
      case this.isWebStarterTier:
        return SubscriptionTier.STARTER;
      case this.isEssentialsTier:
        return SubscriptionTier.ESSENTIALS;
      default:
        return null;
    }
  }

  @computed
  get trialDaysRemaining(): number {
    if (this.expiryTimestamp) {
      return differenceInDays(
        parseDate(this.expiryTimestamp.toString(), 'T', new Date()),
        new Date()
      );
    } else {
      return 0;
    }
  }
  @computed
  get isMonthly(): boolean {
    return !!this.planInterval && /month/.test(this.planInterval);
  }
  @computed
  get isAnnual(): boolean {
    return !!this.planInterval && /annual/.test(this.planInterval);
  }

  constructor(subscription: ApiSubscription) {
    this.accountId = subscription.accountId;
    this.autoRenewing = subscription.autoRenewing;
    this.expiryTimestamp = subscription.expiryTimestamp;
    this.isActive = subscription.isActive;
    this.isTrial = subscription.isTrial;
    this.isGracePeriod = subscription.isGracePeriod;
    this.isUnlimited = subscription.isUnlimited;
    this.orderId = subscription.orderId;
    this.orderSku = subscription.orderSku;
    this.orderTimestamp = subscription.orderTimestamp;
    this.planInterval = subscription.planInterval;
  }
}
