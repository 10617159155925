import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import UserModel from 'src/models/UserModel';
import { useCancelSubscription } from '../CancelSubscriptionContext';
import { TypeForm } from '../components/TypeForm';

import './CancelScreens.scss';

import { Platform } from '@invoice-simple/common';

import { TypeFormSurveyID } from '../common';
import { trackMobileWebviewEvent } from '../utils/trackMobileWebviewEvent';

interface Props {
  user: UserModel;
  formId: TypeFormSurveyID;
  platform: Platform;
  onError: (error: { title?: string; body?: string }) => void;
}

const FeedbackScreen = ({ user, formId, platform }: Props) => {
  const { goTo } = useCancelSubscription();

  useEffect(() => {
    if (platform === Platform.WEB) {
      user.events.trackAction('subscription-cancel-pageViewed', {
        page: 'survey'
      });
      return;
    }

    trackMobileWebviewEvent({
      event: 'webview',
      meta: {
        feature: 'cancellation-survey',
        action: 'survey-page-viewed',
        platform
      }
    });
  }, []);

  return (
    <section className="text-center cancel-screen">
      <img src="/images/logo-gradient-and-title.png" />
      <h1>Sad to see you go</h1>
      <p className="cancel-content">
        We’re sorry to hear you wish to cancel. We would really appreciate your feedback on
        improving our product.
      </p>
      <TypeForm
        formId={formId}
        onComplete={() => {
          goTo('value-props');
        }}
        user={user}
        platform={platform}
      />
    </section>
  );
};

export default observer(FeedbackScreen);
