import React, { FC } from 'react';

import { defineMessages } from 'react-intl';

import { useISIntl } from 'src/i18n';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import {
  OnboardOrigin,
  PaymentEventActivePaymentProvider,
  PaymentEventComponent
} from 'src/payments/types';
import { getPaymentEventSource } from 'src/payments/utils';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';
import { ButtonContainer, TopContainer, ValueProps } from '../../PaymentBasicElements';
import { PaymentModalWrapper } from '../PaypalModalWrapper';

const messages = defineMessages({
  unlockOnlinePayments: {
    id: 'payments.modal.title.unlockOnlinePayments',
    defaultMessage: 'Unlock Online Payments'
  },
  withFooter: {
    id: 'payments.modal.currencyMismatch.withFooter',
    defaultMessage:
      "If you switch the currency on this invoice, you'll be updating it for all your invoices, and–good news–online payments are unlocked!"
  },
  withoutFooter: {
    id: 'payments.modal.currencyMismatch.withoutFooter',
    defaultMessage:
      'If you switch the currency, you’ll be updating it for all your invoices, and–good news–online payments are unlocked!'
  },
  switchCurrency: {
    id: 'payments.modal.currencyMismatch.switchCurrency',
    defaultMessage: 'Switch Currency'
  }
});
export const PaymentsCurrencyMismatchFixModalComponent: FC<{
  activePaymentProvider: PaymentEventActivePaymentProvider;
  hideModal: () => void;
  origin: OnboardOrigin;
  setCurrency: () => void;
  isOpen: boolean;
  store: AppStore;
}> = ({ activePaymentProvider, hideModal, origin, setCurrency, isOpen, store }) => {
  if (!isOpen) return null;

  return (
    <PaymentsCurrencyMismatchFixModalComponentShown
      activePaymentProvider={activePaymentProvider}
      hideModal={hideModal}
      origin={origin}
      setCurrency={setCurrency}
      isOpen={isOpen}
      store={store}
    />
  );
};

const PaymentsCurrencyMismatchFixModalComponentShown: FC<{
  activePaymentProvider: PaymentEventActivePaymentProvider;
  hideModal: () => void;
  origin: OnboardOrigin;
  setCurrency: () => void;
  isOpen: boolean;
  store: AppStore;
}> = ({ activePaymentProvider, hideModal, origin, setCurrency, isOpen, store }) => {
  const { ft, fta } = useISIntl();

  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    activePaymentProvider,
    component: PaymentEventComponent.modalCurrencyMismatchFix
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, store.user.events);

  return (
    <PaymentModalWrapper isOpen={isOpen} onRequestClose={hideModal}>
      <TopContainer
        onClickXIcon={hideModal}
        headerMessage={fta(messages.unlockOnlinePayments)}
        dataTestId="paymentsCurrencyMismatchFixHeader"
      />
      <main>
        <ValueProps>
          <div className="grid grid-cols-1 gap-10 leading-9 p-2">
            {origin === OnboardOrigin.webInvoiceList && (
              <div data-testid="invoiceCurrencyMismatch">{ft(messages.withFooter)}</div>
            )}
            {origin === OnboardOrigin.webSettings && (
              <div data-testid="settingsCurrencyMismatch">{ft(messages.withoutFooter)}</div>
            )}
          </div>
        </ValueProps>
      </main>
      <ButtonContainer
        handleClick={setCurrency}
        buttonMessage={ft(messages.switchCurrency)}
        dataTestId="setCurrencyMismatchFixCurrency"
      />
    </PaymentModalWrapper>
  );
};

export const PaymentsCurrencyMismatchFixModal = injectStore(
  PaymentsCurrencyMismatchFixModalComponent
);
