import { useFlags } from 'flagsmith/react';

import Rollbar from 'src/analytics/rollbar';
import { isValidEmail } from './isValidEmail';

declare global {
  interface Window {
    Verisoul?: {
      account: (args: { id: string; email?: string }) => Promise<void>;
      reinitialize: () => Promise<void>;
    };
  }
}

export const trackVerisoulUser = async ({ id, email }: { id?: string; email?: string }) => {
  const { verisoul } = useFlags(['verisoul']);
  if (!verisoul.enabled) return;

  const isValidInput = id && isValidEmail(email);
  return (
    isValidInput &&
    window.Verisoul?.account({
      id,
      email
    }).catch((error) => {
      Rollbar.trackError('Failed to track Verisoul user', error);
    })
  );
};

export const resetVerisoulUser = async () => {
  const { verisoul } = useFlags(['verisoul']);
  if (!verisoul.enabled) return;

  return window.Verisoul?.reinitialize().catch((error) =>
    Rollbar.trackError('Failed to reset Verisoul session', error)
  );
};
