import { createBrowserHistory, History, Location } from 'history';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import { action, computed, observable } from 'mobx';
import uniloc from 'uniloc';

import { DocType, DocTypes } from '@invoice-simple/common';

import { ENABLED_LOCALES } from 'src/i18n/enabledLocales';
import { queryParamsToObject } from 'src/util/url';
import Intercom from '../analytics/intercom';
import hostOptions from '../data/hostOptions';
import environmentStore from '../stores/EnvironmentStore';
import { Route } from '../types/Route';
import { RouteOptions } from '../types/RouteOptions';
import { docTypeToLabel, docTypeToName } from '../util/docType';
import alertModel from './AlertModel';

const publicPaths = ['/login', '/password'];

const SEORoutes = {
  'GET /': 'invoiceCreate',
  'GET /invoice-generator': 'invoiceCreate',
  'GET /receipt-template/receipt-maker': 'receiptCreate',
  'GET /estimate-template/estimate-maker': 'estimateCreate'
};

ENABLED_LOCALES.forEach((locale) =>
  locale.paths.forEach((path) => (SEORoutes[`GET ${path}`] = 'invoiceCreate'))
);

export class LocationModel {
  history: History; // Browser history

  @observable observableLocation: Location;

  constructor() {
    this.history = createBrowserHistory();
    this.history.listen(this._handleNavigation.bind(this));
    this.observableLocation = this.history.location;
  }

  isPublicPath(): boolean {
    return publicPaths.indexOf(this.pathname) !== -1;
  }

  // Takes a string containing a path to be matched
  // Returns a regex that map to a path, replacing * with any alphanumeric character
  pathToRegex(path: string) {
    return new RegExp(`^${path.replace(/\*/g, '[a-zA-Z0-9]+')}$`);
  }

  // Returns an array of paths that are acessible to guest users
  // (i.e. users who are not logged in but have installation cookies)
  getGuestPaths(): { staticPaths: string[]; dynamicPaths: RegExp[] } {
    // Static paths (do not change based on input)
    const staticPaths: string[] = [
      '/',
      '/invoices',
      '/invoices/new',
      '/estimates',
      '/estimates/new',
      '/clients',
      '/clients/new',
      '/items',
      '/items/new',
      '/settings',
      '/upgrade',
      '/subscription',
      '/signup',
      '/signup-sms',
      '/receipt-template/receipt-maker',
      '/estimate-template/estimate-maker'
    ];

    // Dynamic paths (variable input, need to be converted to regex)
    const dynamicPaths: string[] = [
      '/invoices/*',
      '/invoices/*/edit',
      '/invoices/*/email',
      '/invoices/*/history',
      '/invoices/*/payment',
      '/estimates/*/edit',
      '/estimates/*/email',
      '/estimates/*/history',
      '/clients/*/edit',
      '/items/*/edit'
    ];

    // Regex paths (variable input, already regex)
    const regexPaths: RegExp[] = [
      /^\/v\/[a-zA-Z0-9]+$/ // Public invoice path
    ];

    return { staticPaths, dynamicPaths: [...dynamicPaths.map(this.pathToRegex), ...regexPaths] };
  }

  isGuestPath(path?: string): boolean {
    const testPath = path ?? this.pathname;
    const { staticPaths, dynamicPaths } = this.getGuestPaths();
    return includes(staticPaths, testPath) || dynamicPaths.some((r) => r.test(testPath));
  }

  @computed
  get pathname(): string {
    return this.observableLocation.pathname;
  }
  @computed
  get search(): string {
    return this.observableLocation.search;
  }
  @computed
  get hash(): string {
    return this.observableLocation.hash;
  }
  @computed
  get fullPath(): string {
    return [
      this.observableLocation.pathname,
      this.observableLocation.search,
      this.observableLocation.hash
    ].join('');
  }

  @computed
  get path(): string {
    return [this.pathname, this.search, this.hash].join('');
  }

  @computed
  get name(): string {
    return this.route.name || '';
  }
  @computed
  get id(): string {
    return this.options.id || '';
  }

  @computed
  get docAction(): string {
    if (this.isEmail) {
      return 'email';
    }
    if (this.isPayment) {
      return 'payment';
    }
    if (this.isEdit) {
      return 'edit';
    }
    return 'view';
  }

  @computed
  get docType(): DocType {
    if (this.isEstimate) {
      return DocTypes.DOCTYPE_ESTIMATE;
    }
    if (this.isReceipt) {
      return DocTypes.DOCTYPE_STATEMENT;
    }
    return DocTypes.DOCTYPE_INVOICE;
  }

  @computed
  get docTypeName(): string {
    return docTypeToName(this.docType);
  }

  @computed
  get docTypeLabel(): string {
    return docTypeToLabel(this.docType);
  }

  @computed
  get isNew(): boolean {
    return /create/i.test(this.name);
  }

  @computed
  get isRoot(): boolean {
    return this.path === '/';
  }

  @computed
  get isGuest(): boolean {
    return /invoiceCreate/.test(this.name);
  }

  @computed
  get isEdit(): boolean {
    return /edit|create/i.test(this.name);
  }
  @computed
  get isEditable(): boolean {
    return /edit|create|view|email|payment/i.test(this.name) && !this.isReviewRedirect;
  }
  @computed
  get isEmail(): boolean {
    return /email/i.test(this.name);
  }
  @computed
  get isDocHistory(): boolean {
    return /(invoice|estimate)history/i.test(this.name);
  }
  @computed
  get isPayment(): boolean {
    return /payment/i.test(this.name);
  }
  @computed
  get isCheckout(): boolean {
    return /checkout$/i.test(this.name);
  }
  @computed
  get isView(): boolean {
    return /view/i.test(this.name) && !this.isReviewRedirect;
  }
  @computed
  get isCreate(): boolean {
    return /create$/i.test(this.name);
  }
  @computed
  get isReceipt(): boolean {
    return /receipt/i.test(this.name);
  }
  @computed
  get isInvoice(): boolean {
    return /invoice/i.test(this.name);
  }
  @computed
  get isSubscription(): boolean {
    return /subscription/i.test(this.name) && !this.name.toLowerCase().includes('cancel');
  }
  @computed
  get isDoc(): boolean {
    return /invoice|estimate|receipt/i.test(this.name);
  }
  @computed
  get isGuestDoc(): boolean {
    return this.isDoc && /create/i.test(this.name);
  }
  @computed
  get isPrivateDoc(): boolean {
    return this.isDoc && /email|edit|payment|view/i.test(this.name);
  }

  @computed
  get isEstimate(): boolean {
    return /estimate/i.test(this.name);
  }
  @computed
  get isClient(): boolean {
    return /client/i.test(this.name);
  }
  @computed
  get isItem(): boolean {
    return /item/i.test(this.name);
  }
  @computed
  get isReport(): boolean {
    return /report/i.test(this.name);
  }
  @computed
  get isPaymentsDashboard(): boolean {
    return /paymentsDashboard/i.test(this.name);
  }
  @computed
  get isSetting(): boolean {
    return /setting/i.test(this.name);
  }

  @computed
  get isSignup(): boolean {
    return /signup/i.test(this.name);
  }

  @computed
  get isLogin(): boolean {
    return /login/i.test(this.name);
  }

  @computed
  get isInvoicesList(): boolean {
    return 'invoiceList' === this.name;
  }

  @computed
  get isReviewRedirect(): boolean {
    return this.name === 'requestReviewRedirect';
  }

  @computed
  get isCanonical(): boolean {
    return (
      (this.isSeoHost() || environmentStore.isSnapshot()) &&
      /invoiceCreate|receiptCreate|estimateCreate|signup|login|logout|password|subscription/i.test(
        this.name
      )
    );
  }

  @computed
  get isHrefLang(): boolean {
    return /invoiceCreate/i.test(this.name) && (this.isSeoHost() || environmentStore.isSnapshot());
  }

  @computed
  get isCreatingDocument(): boolean {
    return /invoiceCreate|estimateCreate|receiptCreate/.test(this.name);
  }

  @computed
  get canonicalDocName(): string {
    return /estimate/.test(this.name) ? 'estimate' : 'invoice';
  }

  @computed
  get isCancelSubscriptionPage(): boolean {
    return this.name === 'subscriptionCancel';
  }

  @computed
  get isCancelSubscriptionPageV2(): boolean {
    return this.name.includes('subscriptionCancelV2');
  }

  @computed
  get route(): Route {
    return this.router.lookup(this.fullPath);
  }
  @computed
  get options(): RouteOptions {
    return this.route.options;
  }

  @computed
  get router(): {
    lookup: (path: string) => Route;
    generate: (name: string, opts: RouteOptions) => string;
  } {
    return uniloc(
      {
        // public invoice
        invoicePublic: 'GET /v/:id',
        invoicePublicCheckout: 'GET /v/:id/pay',
        signup: 'GET /signup',
        signupSms: 'GET /sms-signup',
        login: 'GET /login',
        upgrade: 'GET /upgrade',
        logout: 'GET /logout',
        password: 'GET /password',
        invoiceCreate: 'GET /invoices/new',
        estimateCreate: 'GET /estimates/new',
        receiptCreate: 'GET /receipts/new',
        subscription: 'GET /subscription',
        settingList: 'GET /settings',
        invoiceList: 'GET /invoices',
        invoiceView: 'GET /invoices/:id',
        invoiceEdit: 'GET /invoices/:id/edit',
        invoiceEmail: 'GET /invoices/:id/email',
        invoiceHistory: 'GET /invoices/:id/history',
        invoicePayment: 'GET /invoices/:id/payment',
        estimateList: 'GET /estimates',
        estimateView: 'GET /estimates/:id',
        estimateEdit: 'GET /estimates/:id/edit',
        estimateEmail: 'GET /estimates/:id/email',
        estimateHistory: 'GET /estimates/:id/history',
        clientList: 'GET /clients',
        clientCreate: 'GET /clients/new',
        clientEdit: 'GET /clients/:id/edit',
        itemList: 'GET /items',
        itemEdit: 'GET /items/:id/edit',
        itemCreate: 'GET /items/new',
        learnMore: 'GET /paypal/learnmore',
        onboarding: 'GET /paypal/onboarding',
        paymentsDashboard: 'GET /payments/dashboard',
        reportList: 'GET /reports',
        accountDelete: 'GET /account-delete',
        subscriptionCancelV2: 'GET /subscription-cancel-v2',
        subscriptionCancelV2Mobile: 'GET /subscription-cancel-v2-mobile',
        requestReviewRedirect: 'GET /request-review/redirect'
      },
      SEORoutes
    );
  }

  public getLocale() {
    const result = ENABLED_LOCALES.find(
      (enabledLocale) => !!enabledLocale.paths.find((path) => path === this.fullPath)
    );
    return result && result.locale;
  }

  public isSeoHost(host?: string): boolean {
    return includes(keys(hostOptions), host || window.location.host);
  }

  public scrollTop(): void {
    document.body.scrollTop = 0;
    if (document.documentElement) {
      document.documentElement.scrollTop = 0;
    }
  }

  public redirectHard(path: string) {
    window.location.replace(path);
  }

  public navigateHard(path: string, preserveQueryParams = false) {
    const queryParams = new URLSearchParams(window.location.search).toString();
    const pathWithQueryParams = queryParams ? `${path}?${queryParams}` : path;

    window.location.assign(preserveQueryParams ? pathWithQueryParams : path);
  }

  public navigateTo(path: string) {
    if (this.isSeoHost()) {
      this.navigateHard(path);
    } else {
      this.history.push(path);
    }
  }

  public nav(name: string, opts: RouteOptions = {}, preserveQueryParams = false): void {
    const queryParams = new URLSearchParams(window.location.search);
    const options = { ...opts, ...(preserveQueryParams && queryParamsToObject(queryParams)) };
    if (this.isSeoHost()) {
      // wwww -> app
      this.navigateHard(this.buildUrl(name, options));
    } else {
      alertModel.resetAlert();
      // app -> app
      if (name !== this.name) {
        const path = name === 'home' ? '/' : this.buildPath(name, options);
        if (this.isCreatingDocument) {
          this.history.replace(path);
        } else {
          this.history.push(path, { appToApp: true });
        }
      }
    }
  }
  public navAndScrollTop(name: string, opts: RouteOptions = {}): void {
    this.nav(name, opts);
    this.scrollTop();
  }

  buildUrl(name: string, opts: RouteOptions): string {
    return [environmentStore.appUrl, this.buildPath(name, opts)].join('');
  }

  buildPath(name: string, opts: RouteOptions): string {
    return this.router.generate(name, opts);
  }

  // https://www.intercom.com/help/install-on-your-product-or-site/other-ways-to-get-started/integrate-intercom-in-a-single-page-app
  _handleNavigation(): void {
    Intercom('update');
    this.observableLocation = this.history.location;
  }

  // for tests and SSR
  @action.bound
  public _setLocation(location: { pathname: string; search?: string; hash?: string }) {
    this.observableLocation = { ...{ search: '', state: null, hash: '' }, ...location };
  }
}

export default new LocationModel();
