import React, { useEffect, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { z } from 'zod';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { defineMessages, useISIntl } from 'src/i18n';
import location from 'src/models/LocationModel';
import UserModel from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { SubscriptionTrackerStore } from 'src/subs';
import { hasFreeTrialEnded } from 'src/util/freeTrial';
import injectStore from 'src/util/injectStore';
import { isOnboardingV1Enabled } from 'src/util/onboarding';
import { isStripePurchaseTransferrable } from 'src/util/subscriptions';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import alertModel, { AlertType } from '../models/AlertModel';
import { zodResolver } from '../util/zod';
import Alert from './Alert';
import EmailInput from './EmailInput';
import { BrandHeader } from './Onboarding/brand-header';
import { Footer } from './Onboarding/footer';
import { Header } from './Onboarding/header';
import { InputWithLabel } from './Onboarding/input-with-label';
import { OnboardingContainer } from './Onboarding/page-container';

const messages = defineMessages({
  title: {
    id: 'login.title',
    defaultMessage: 'Login'
  },
  description: {
    id: 'login.description',
    defaultMessage: 'Welcome Back! Login to your Invoice Simple account to view all your invoices.'
  },
  header: {
    id: 'login.header',
    defaultMessage: 'Login to your Account'
  },
  subHeader: {
    id: 'login.body',
    defaultMessage: "Welcome Back, we hope you're having a great day."
  },
  formLabelEmail: {
    id: 'login.form.labels.email',
    defaultMessage: 'Email'
  },
  formLabelPassword: {
    id: 'login.form.labels.password',
    defaultMessage: 'Password'
  },
  formPlaceholderEmail: {
    id: 'login.form.placeholders.email',
    defaultMessage: 'name@example.com'
  },
  formButtonLabel: {
    id: 'login.form.button.label',
    defaultMessage: 'Login'
  },
  formButtonLabelPending: {
    id: 'login.form.button.label.pending',
    defaultMessage: 'Logging In...'
  },
  formButtonLabelLoading: {
    id: 'login.form.button.label.loading',
    defaultMessage: 'Loading...'
  },
  cancelButtonLabel: {
    id: 'login.cancel.button.label',
    defaultMessage: 'Cancel'
  },
  signupLinkQuestion: {
    id: 'login.link.signup.question',
    defaultMessage: "Don't have an account?"
  },
  signupLinkLabel: {
    id: 'login.link.signup.label',
    defaultMessage: 'Sign Up'
  },
  forgotPasswordLabel: {
    id: 'login.link.forgotPassword.label',
    defaultMessage: 'Forgot your password?'
  },
  alertLoggedOutTitle: {
    id: 'login.alert.loggedOut.title',
    defaultMessage: 'Logged Out'
  },
  alertLoggedOutBody: {
    id: 'login.alert.loggedOut.body',
    defaultMessage: "Bye for now, Maybe we'll see you on another device soon?"
  },
  alertAccountRequiredTitle: {
    id: 'login.alert.accountRequired.title',
    defaultMessage: 'Account Required'
  },
  alertAccountRequiredBody: {
    id: 'login.alert.accountRequired.body',
    defaultMessage: 'Please login to continue.'
  },
  alertSessionExpiredTitle: {
    id: 'login.alert.sessionExpired.title',
    defaultMessage: 'Session Expired'
  },
  alertSessionExpiredBody: {
    id: 'login.alert.sessionExpired.body',
    defaultMessage: 'Please login to continue.'
  },
  alertLoginSuccessTitle: {
    id: 'login.alert.login.success.title',
    defaultMessage: 'Hey {userDisplayName}'
  },
  alertLoginSuccessBody: {
    id: 'login.alert.login.success.body',
    defaultMessage: 'Great to have you back.'
  }
});

const setAlert = (formatMessage) => {
  switch (window.location.hash) {
    case '#loggedOut':
      alertModel.setAlert(
        'success',
        formatMessage(messages.alertLoggedOutTitle),
        formatMessage(messages.alertLoggedOutBody)
      );
      break;
    case '#accountRequired':
      alertModel.setAlert(
        'warning',
        formatMessage(messages.alertAccountRequiredTitle),
        formatMessage(messages.alertAccountRequiredBody)
      );
      break;
    case '#sessionExpired':
      alertModel.setAlert(
        'warning',
        formatMessage(messages.alertSessionExpiredTitle),
        formatMessage(messages.alertSessionExpiredBody)
      );
      break;
  }
};

interface LoginProps {
  subLogin?: boolean;
  store?: AppStore;
}

export interface LoginState {
  loadingUser: boolean;
  loading: boolean;
}

export interface LoginData {
  email: string;
  password: string;
}

const LoginComponent = (props: LoginProps) => {
  const user = props.store!.user;
  const { ft, formatMessage } = useISIntl();

  const [loadingUser, setLoadingUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState<LoginData>({
    email: '',
    password: ''
  });

  const freeTrialEnded = hasFreeTrialEnded();

  const showLoadingSpinner = loadingUser || SubscriptionTrackerStore.isVerifying;

  const onboardingV1Enabled = useMemo(isOnboardingV1Enabled, []);
  const formId = 'login-form-id';

  const getZodObject = () => {
    return z.object({
      email: z.string().email(ft({ id: 'error.invalidEmail' })),
      password: z.string().min(6, ft({ id: 'signup.form.labels.passwordLength' }))
    });
  };
  const LoginSchema = useMemo(getZodObject, []);
  type LoginSchema = z.infer<typeof LoginSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus
  } = useForm<LoginSchema>({
    resolver: zodResolver(LoginSchema)
  });

  useEffect(() => {
    if (!loginData.email) {
      setFocus('email');
    }
  }, [loginData]);

  useEffect(() => {
    const stripeSessionId = getURLQueryParam('sessionId') || '';
    const stripeSuccess = getURLQueryParam('success');

    const load = async () => {
      // load user entities to trigger SubscriptionPurchaseTracker
      if (stripeSuccess && stripeSessionId && !props.store!.user.isLoaded) {
        setLoadingUser(true);
        await props.store!.user.loadUserEntities().finally(() => setLoadingUser(false));
      }

      setAlert(formatMessage);
    };

    load();
    UserModel.getInstance().trackAppEventViaApi('login-view');
  }, []);

  useEffect(() => {
    const emailSearchParam = new URLSearchParams(window.location.search).get('email');

    if (emailSearchParam) {
      setLoginData({ email: emailSearchParam, password: '' });
    }
  }, []);

  const handleSubmitV1 = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    alertModel.resetAlert();
    user.loginData = loginData;

    await submitLogin();
  };

  const handleSubmitV2: SubmitHandler<LoginSchema> = async (data) => {
    user.loginData = data;

    await submitLogin();
  };

  const submitLogin = async () => {
    setLoading(true);

    const returnPath = getURLQueryParam(URLQueryParamKeys.RETURN_PATH) || undefined;
    const subscriptionFeature = getURLQueryParam('feature') || undefined;
    const subscriptionCoupon = getURLQueryParam('coupon') || undefined;
    const subscriptionCampaign = getURLQueryParam('campaign') || undefined;
    const stripeSessionId = getURLQueryParam('sessionId') || '';
    const stripeSuccess = getURLQueryParam('success');

    const getPostLoginReturnPath = () => {
      if (!returnPath) {
        return;
      }

      const queryParams: string[] = [];
      if (subscriptionFeature) {
        queryParams.push(`feature=${subscriptionFeature}`);
      }
      if (subscriptionCoupon) {
        queryParams.push(`coupon=${subscriptionCoupon}`);
      }
      if (subscriptionCampaign) {
        queryParams.push(`campaign=${subscriptionCampaign}`);
      }

      return queryParams.length > 0 ? `${returnPath}?${queryParams.join('&')}` : returnPath;
    };

    user.isLoggingIn = true;

    const withSubscriptionTransfer =
      !!stripeSuccess &&
      (await isStripePurchaseTransferrable(stripeSessionId).catch(() => {
        user.isLoggingIn = false;
        return false;
      }));

    const response = await user
      .login({
        postLoginReturnPath: getPostLoginReturnPath(),
        withSubscriptionTransfer,
        onboardingV1Enabled
      })
      .finally(() => setLoading(false));

    if (response) handleError(response);
  };

  const handleError = (error: string) => {
    const baseAlertObject = {
      type: 'danger' as AlertType,
      isClosable: false
    };

    if (error === 'INVALID_RESPONSE') {
      alertModel.setAlertObject({ ...baseAlertObject, body: ft({ id: 'error.errorHasOccurred' }) });
      return;
    }

    alertModel.setAlertObject({ ...baseAlertObject, body: ft({ id: 'error.wrongLoginData' }) });
  };

  const handleNav = (path: string, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    location.navigateHard(path, true);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    location.navigateHard('/');
  };

  const onChange = (name: string, value: string) => {
    setLoginData({ ...loginData, [name]: value });
  };

  return (
    <>
      {onboardingV1Enabled && (
        <div id="tailwind" className="w-full h-full md:bg-inherit">
          <BrandHeader />
          <section className="flex justify-center w-full h-full">
            <OnboardingContainer>
              <Header title={ft({ id: 'login.header.invoiceSimple' })} />
              <div className="w-full">
                <Alert version="v2" />
              </div>
              <form
                id={formId}
                className={`w-full flex flex-col gap-[25px] ${alertModel.isVisible ? 'mt-6' : ''}`}
                onSubmit={handleSubmit(handleSubmitV2)}>
                <InputWithLabel
                  id="email"
                  type="text"
                  label={ft({
                    id: 'auth.form.labels.emailAddress'
                  })}
                  placeholder="name@example.com"
                  errorMessage={errors.email?.message}
                  {...register('email')}
                />
                <InputWithLabel
                  id="password"
                  type="password"
                  label={ft({
                    id: 'login.form.labels.password'
                  })}
                  placeholder="******"
                  errorMessage={errors.password?.message}
                  {...register('password')}
                />
                <a
                  href="/auth/reset-password"
                  className="w-full text-center text-[14px] p-[2px] text-gray-600"
                  onClick={(e) => handleNav('/auth/reset-password', e)}>
                  <FormattedMessage {...messages.forgotPasswordLabel} />
                </a>
              </form>
              <Footer
                formId={formId}
                primaryButtonLoading={loading}
                onSecondaryButtonClick={() => location.navigateHard('/')}
                footerLink={{
                  href: '/signup',
                  text: `${ft({ id: 'login.link.signup.question.noAccount' })} ${ft({
                    id: 'login.link.signup.prompt'
                  })}`
                }}
              />
            </OnboardingContainer>
          </section>
        </div>
      )}

      {!onboardingV1Enabled && (
        <div className="login">
          <Helmet>
            <title itemProp="name">{ft(messages.title)}</title>
            <body className="app-theme app-theme-focus" />
            <meta name="description" content={ft(messages.description)} />
          </Helmet>

          <div className="focus-container">
            <div className="focus-header">
              <a className="brand" href="/" onClick={(e) => handleNav('/', e)} tabIndex={-1}>
                <img
                  className="brand-logo"
                  src="/images/logo.png"
                  role="presentation"
                  alt="invoice simple logo"
                  data-pin-nopin="true"
                />
              </a>
              <h1>
                <FormattedMessage {...messages.header} />
              </h1>
              <h2>
                <FormattedMessage {...messages.subHeader} />
              </h2>
            </div>
            <div className="focus-body">
              {showLoadingSpinner ? (
                <div className="alert-loading d-flex justify-content-center pb-4">
                  <FontAwesomeIcon icon="spinner" size="lg" color="black" spin />
                </div>
              ) : (
                <Alert />
              )}
              <form acceptCharset="UTF-8" onSubmit={(e) => handleSubmitV1(e)}>
                <div className="form-group">
                  <label htmlFor="email">
                    <FormattedMessage {...messages.formLabelEmail} />
                  </label>
                  <EmailInput
                    key="user-login"
                    name="email"
                    placeholder={ft(messages.formPlaceholderEmail)}
                    disabled={user.isLoggingIn}
                    value={loginData.email}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    autoFocus={!loginData.email}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">
                    <FormattedMessage {...messages.formLabelPassword} />
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="******"
                    disabled={user.isLoggingIn}
                    value={loginData.password}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                  />
                  <div className="forgot-password-wrapper mt-3">
                    <a
                      href="/password"
                      className="help-text"
                      onClick={(e) => handleNav('/password', e)}>
                      <FormattedMessage {...messages.forgotPasswordLabel} />
                    </a>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-block btn-prime"
                    disabled={user.isLoggingIn}>
                    {user.isLoggingIn ? (
                      <FormattedMessage {...messages.formButtonLabelPending} />
                    ) : user.isLoggingIn ? (
                      <FormattedMessage {...messages.formButtonLabelLoading} />
                    ) : (
                      <FormattedMessage {...messages.formButtonLabel} />
                    )}
                  </button>
                  <a className="btn btn-cancel" href="/" onClick={handleCancel}>
                    <FormattedMessage {...messages.cancelButtonLabel} />
                  </a>
                </div>
              </form>
            </div>
            {!freeTrialEnded && (
              <div className="focus-footer">
                <span>
                  <FormattedMessage {...messages.signupLinkQuestion} />
                </span>
                <a href="/signup" className="btn" onClick={(e) => handleNav('/signup', e)}>
                  <FormattedMessage {...messages.signupLinkLabel} />
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export const Login = injectStore(LoginComponent);
