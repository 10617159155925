import { ConsentStatus, ConsentType } from '@invoice-simple/common';

import { getConsentData, SaveConsentAccountData, saveConsentData } from 'src/apis/consentAPI';
import alertModel from 'src/models/AlertModel';
import UserModel from 'src/models/UserModel';
import { OnboardOrigin, PaymentEventPaymentsHolder, PaymentEventSource } from '../types';

export const PAYMENT_MODAL_DELAYED_TIME = 1500;
export const PAYMENT_MODAL_LONG_DELAYED_TIME = 2000;
export const PAYMENTS_QR_PLACEHOLDER_URL = 'https://www.invoicesimple.com/';

export const redirectToPaypalDashboard = () => {
  if (process.env.REACT_APP_PAYPAL_DASHBOARD_URL) {
    window.open(process.env.REACT_APP_PAYPAL_DASHBOARD_URL, '_blank');
  } else {
    throw new Error('No PAYPAL_DASHBOARD_URL setup');
  }
};

export const onError = ({ title, body }: { title: string; body: string }) => {
  alertModel.setAlert('danger', title, body);
};

export const hasCurrencyMismatch = (
  paymentProcessorCurrency?: string | null,
  currencyCode?: string
) => {
  if (!paymentProcessorCurrency || !currencyCode) {
    return false;
  }

  return paymentProcessorCurrency.toLowerCase() !== currencyCode.toLowerCase();
};

export interface SaveConsentAccountInput {
  user: UserModel;
  consentMessage: string;
  consentValue?: string;
  consentType: ConsentType;
}

export const saveConsent = async ({
  user,
  consentMessage,
  consentValue,
  consentType
}: SaveConsentAccountInput): Promise<void> => {
  if (!consentValue) {
    consentValue = user.email;
  }
  if (!consentValue) return;

  const consentData = await getConsentData({
    consentType,
    consentValue,
    user
  });

  if (!consentData.consent) {
    const data: SaveConsentAccountData[] = [
      {
        consentType,
        consentValue,
        consentStatus: ConsentStatus.CONSENT_IMPLICIT,
        consentMessage
      }
    ];

    try {
      await saveConsentData({ user, data });
    } catch (error) {
      console.error('Error with saving consent data: ', error);
    }
  }
};

export const getPlaceHolder = (origin: OnboardOrigin) => {
  return origin === OnboardOrigin.webSettings
    ? PaymentEventPaymentsHolder.settings
    : PaymentEventPaymentsHolder.invoiceEditor;
};

export const getPaymentEventSource = () => {
  const urlPath = window.location.pathname.slice(1);
  switch (true) {
    case urlPath === 'settings':
      return PaymentEventSource.settings;
    case urlPath === 'invoices':
      return PaymentEventSource.invoiceList;
    case urlPath === '' || urlPath.startsWith('invoices/'):
      return PaymentEventSource.invoiceEditor;
    case urlPath.startsWith('estimates/'):
      return PaymentEventSource.estimateEditor;
    case urlPath.startsWith('payments'):
      return PaymentEventSource.paymentsDashboard;
    default:
      return PaymentEventSource.notDefined;
  }
};
