import React from 'react';

import { Platform } from '@invoice-simple/common';

import UserModel from 'src/models/UserModel';
import { trackMobileWebviewEvent } from '../utils/trackMobileWebviewEvent';

interface ReturnToAppButtonProps {
  user: UserModel;
  platform: Platform;
}

export const ReturnToAppButton = ({ user, platform }: ReturnToAppButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (platform === Platform.WEB) {
      user.events.trackAction('subscription-cancel-returnToAppButtonClicked');
      return window.location.assign('/');
    }

    trackMobileWebviewEvent({
      event: 'webview',
      meta: {
        feature: 'cancellation-survey',
        action: 'return-to-app',
        platform
      }
    });
    window.location.href = 'settings://index';
  };

  return (
    <div className="flex content-center flex-col space-y-6 self-center mt-16">
      <button
        data-testid="return-to-app-button"
        className="rounded-xl bg-orange-is border-transparent self-center py-3 px-4 text-white text-3xl font-bold"
        onClick={handleClick}>
        Back to Invoice Simple
      </button>
    </div>
  );
};
