import React from 'react';

import { FeatureName } from '@invoice-simple/feature-gate';

import LocationModel from 'src/models/LocationModel';
import { hasFreeTrialEnded } from 'src/util/freeTrial';
import { getURLQueryParam, NextJSRefValues, URLQueryParamKeys } from 'src/util/url';

export const BackButton = () => {
  const isFeature = (value: string): value is FeatureName => {
    return Object.values(FeatureName).includes(value as FeatureName);
  };
  return (
    <div data-testid="btn-back" className="lg:absolute py-4">
      <a
        onClick={(e) => {
          e.preventDefault();
          const referrer = getURLQueryParam(URLQueryParamKeys.REF);
          const feature = getURLQueryParam(URLQueryParamKeys.FEATURE);
          const isFromInstantForm = referrer === 'instant-form';
          const isFromNextJSApp = Object.values(NextJSRefValues).includes(
            referrer as NextJSRefValues
          );
          const isFeatureGate = isFeature(feature || '');

          const returnPath = getURLQueryParam(URLQueryParamKeys.RETURN_PATH);
          const origin = getURLQueryParam(URLQueryParamKeys.ORIGIN);

          const freeTrialEnded = hasFreeTrialEnded();
          if (freeTrialEnded) {
            return window.location.assign('/login');
          }

          if (returnPath) {
            return window.location.assign(`${returnPath}${origin ? `?origin=${origin}` : ''}`);
          }

          if (isFromNextJSApp) {
            return LocationModel.history.goBack();
          }
          /**
           * Prevent following loop:
           *   - back button on Stripe Checkout -> paywall -> back button on paywall -> Stripe Checkout
           */
          if (document?.referrer?.includes('stripe')) {
            return window.location.assign('/invoices');
          }
          /**
           * Prevent following loop:
           *   - instant-form -> paywall -> invoice edit -> paywall (due to invoice limit)
           */
          if (isFromInstantForm || !LocationModel.history.location.state?.appToApp) {
            return LocationModel.nav('invoiceList');
          }

          /**
           * Prevent following loop:
           *  - reports -> feature gate -> paywall -> reports
           */
          if (isFeatureGate && feature === FeatureName.REPORTS) {
            return LocationModel.nav('invoiceList');
          }
          LocationModel.history.goBack();
        }}
        className="opacity-60 hover:opacity-80 cursor-pointer">
        <svg
          className="InlineSVG text-gray-800 hover:text-gray-600 Icon Header-backArrow mr2 Icon--sm"
          focusable="false"
          width="12"
          height="12"
          viewBox="0 0 16 16">
          <path
            d="M3.417 7H15a1 1 0 0 1 0 2H3.417l4.591 4.591a1 1 0 0 1-1.415 1.416l-6.3-6.3a1 1 0 0 1 0-1.414l6.3-6.3A1 1 0 0 1 8.008 2.41z"
            fillRule="evenodd"></path>
        </svg>
      </a>
    </div>
  );
};
