import React, { FC } from 'react';

import { defineMessages } from 'react-intl';

import { useISIntl } from 'src/i18n';
import { OnboardOrigin } from 'src/payments/types';
import { ButtonContainer, TopContainer, ValueProps } from '../../PaymentBasicElements';
import { PaymentModalWrapper } from '../PaypalModalWrapper';

const messages = defineMessages({
  header: {
    id: 'paymentsCurrencyMismatchModal.header',
    defaultMessage: 'Heads-up on Currency Changes!'
  },
  descriptionInvoice1: {
    id: 'paymentsCurrencyMismatchModal.descriptionInvoice1',
    defaultMessage:
      "If you switch the currency on this invoice, you'll be updating it for all your invoices, and your online payments will be paused."
  },
  descriptionSettings1: {
    id: 'paymentsCurrencyMismatchModal.descriptionSettings1',
    defaultMessage:
      "If you switch your account currency, you'll be updating it for all your invoices, and your online payments will be paused."
  },
  description2: {
    id: 'paymentsCurrencyMismatchModal.description2',
    defaultMessage:
      'To resume online payments, just make sure the online payments currency matches the currency on your invoices.'
  },
  cta1: {
    id: 'paymentsCurrencyMismatchModal.cta1',
    defaultMessage: 'Continue'
  }
});
export const PaymentsCurrencyMismatchModal: FC<{
  hideModal: () => void;
  origin: OnboardOrigin;
  setCurrency: () => void;
  isOpen: boolean;
}> = ({ hideModal, origin, setCurrency, isOpen }) => {
  const { ft } = useISIntl();

  return (
    <PaymentModalWrapper isOpen={isOpen} onRequestClose={hideModal}>
      <TopContainer
        onClickXIcon={hideModal}
        headerMessage={ft(messages.header)}
        dataTestId="paymentsCurrencyMismatchHeader"
      />
      <main>
        <ValueProps>
          <div className="grid grid-cols-1 gap-10 leading-9 p-2">
            {origin === OnboardOrigin.webInvoiceList && (
              <div data-testid="invoiceCurrencyMismatch">{ft(messages.descriptionInvoice1)}</div>
            )}
            {origin === OnboardOrigin.webSettings && (
              <div data-testid="settingsCurrencyMismatch">{ft(messages.descriptionSettings1)}</div>
            )}
            <div>{ft(messages.description2)}</div>
          </div>
        </ValueProps>
      </main>
      <ButtonContainer
        handleClick={setCurrency}
        buttonMessage={ft(messages.cta1)}
        dataTestId="setCurrencyMismatchCurrency"
      />
    </PaymentModalWrapper>
  );
};
