import React, { useEffect, useState } from 'react';

import { Platform, SubscriptionTier } from '@invoice-simple/common';
import { FeatureName } from '@invoice-simple/feature-gate';
import { getMaxCouponDiscount } from '@invoice-simple/is-coupon';

import { ISIntl } from 'src/i18n';
import { Cadence } from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { hasFreeTrialEnded } from 'src/util/freeTrial';
import {
  BackButton,
  PlanIntervalSelectorV2,
  SubscriptionTierCards,
  Testimonials,
  TieredMatrixListV2
} from './components';
import { DiscountBanner } from './components/DiscountBanner';
import { useInterval } from './hooks';
import {
  getPaywallVariant,
  getReasonForUpgrade,
  getTierRequired,
  hasValidDiscount,
  maybeShowAlertBanner
} from './utils';
import { messages } from './utils/messages';

interface Props {
  store: AppStore;
  intl: ISIntl;
}

/**
 *
 * Essentials, Plus, Premium tiered Pricing for regular and discounted (coupon) subscriptions
 *
 * **/
export const SubscriptionTieredV3 = ({ store, intl }: Props) => {
  const { user } = store;
  const { formatMessage, ft } = intl;

  const feature = new URLSearchParams(window.location.search).get('feature') as FeatureName;
  const tierRequired = getTierRequired(getReasonForUpgrade(feature, user));
  const currentTier = user.activeSubscriptionTier;
  const paywallVariant = getPaywallVariant(currentTier, tierRequired);
  const { selectedInterval, toggleInterval, shouldDisallowSwitch } = useInterval(
    user.activeSubscriptionCadence
  );

  const defaultTabState = tierRequired === SubscriptionTier.PREMIUM ? 2 : 1;

  const tabState = useState(defaultTabState);

  useEffect(() => {
    maybeShowAlertBanner(store, selectedInterval, intl);
  }, []);

  const couponInfo = user.paywallCouponInfo;

  const onSupportClick = async () => {
    window.open('https://help.invoicesimple.com');
  };

  const showDiscountBanner = couponInfo && hasValidDiscount(couponInfo, selectedInterval);

  const freeTrialEnded = hasFreeTrialEnded();
  const topTableTitle = freeTrialEnded ? messages.freeTrialTableTopTitle : messages.tableTopTitle;
  const topTableSubtitle = freeTrialEnded
    ? messages.freeTrialTableTopSubtitle
    : messages.tableTopSubtitle;

  return (
    <div id="tailwind">
      <div
        className="relative mx-auto sm:py-24 px-4 sm:px-6 lg:px-8"
        style={{ maxWidth: '1000px' }}>
        <BackButton />
        <div className="sm:align-center sm:flex sm:flex-col">
          <div className="hidden md:block md:max-w-[600px] text-5xl font-bold text-gray-900 sm:text-center my-0 mx-auto">
            <img
              data-testid="is-logo"
              className="h-24 sm:h-28 w-auto rounded"
              src="images/is-logo.png"
              alt="Invoice Simple Logo"
            />
            <div className="mt-[10px] sm:mt-[20px] mx-auto">
              <h3
                className="text-center font-semibold mx-auto mb-[10px] sm:max-w-[500px] tracking-normal text-gray-900"
                style={{ fontSize: 20, lineHeight: '28px' }}>
                {ft(topTableTitle)}
              </h3>
              <p
                className="text-center font-normal text-gray-900"
                style={{ fontSize: 14, lineHeight: '20px' }}>
                {ft(topTableSubtitle)}
              </p>
            </div>
          </div>
          <h3
            data-testid="subscribe-header"
            className="block md:hidden text-center font-semibold mx-auto mb-[10px] sm:max-w-[500px] tracking-normal text-gray-900 -mt-8"
            style={{ fontSize: 20, lineHeight: '28px' }}>
            {ft(messages.subscribeHeader)}
          </h3>
          <PlanIntervalSelectorV2
            selectedInterval={selectedInterval}
            onToggleInterval={toggleInterval}
            formatMessage={formatMessage}
            isHidden={user.hasAnnualSub && paywallVariant !== 'default'}
            couponInfo={couponInfo}
            className="mt-10 md:mt-20"
          />
          <div className="mt-8">
            {couponInfo && showDiscountBanner && (
              <DiscountBanner
                title={ft(
                  selectedInterval === Cadence.ANNUAL
                    ? messages.discountBannerTitleYear
                    : messages.discountBannerTitle,
                  {
                    discount: getMaxCouponDiscount({
                      paywallCouponConfig: couponInfo,
                      platform: Platform.WEB,
                      cadence: selectedInterval
                    }),
                    duration: couponInfo.durationMonths
                  }
                )}
                message={ft(messages.discountBannerMessage)}
              />
            )}
          </div>
          <SubscriptionTierCards
            className="mt-10"
            store={store}
            selectedInterval={selectedInterval}
            shouldDisallowSwitch={shouldDisallowSwitch}
          />

          <div className="hidden md:block mb-20" data-testid="testimonials">
            <Testimonials isHidden={paywallVariant !== 'default'} />
          </div>
        </div>

        <div
          className="md:mt-[20px] mb-20 md:mb-0"
          id="feature-matrix"
          data-testid="feature-matrix">
          <TieredMatrixListV2
            store={store}
            intl={intl}
            selectedInterval={selectedInterval}
            shouldDisallowSwitch={shouldDisallowSwitch}
            showTabState={tabState}
            tierRequired={tierRequired}
          />

          <div className="flex flex-col sm:flex-row justify-start sm:justify-center sm:items-center pt-15 pb-20 sm:pb-0 gap-6">
            <p className="text-gray-800 font-normal" style={{ fontSize: 16, lineHeight: '22px' }}>
              {ft(messages.discountedPaywallSupportHelp)}
            </p>

            <button
              type="button"
              onClick={() => onSupportClick()}
              className="!rounded-[6px] border-0 bg-gray-100 px-[21px] py-[13px] w-fit font-medium"
              style={{ fontSize: 14, lineHeight: '22px' }}>
              {ft(messages.discountedPaywallContactSupport)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
