import UserModel from 'src/models/UserModel';

export const isFreeTrialEnabled = () => {
  const user = UserModel.getInstance();

  const isFreeTrialActive =
    user.isSubActive && user.subscriptionOrderSku === 'com.invoicesimple.freetrial.14day';

  return isFreeTrialActive && !user.hadPaidSubsInThePast;
};

export const hasFreeTrialEnded = () => {
  if (isFreeTrialEnabled()) {
    return false;
  }

  const user = UserModel.getInstance();

  return !user.isSubActive && !user.hadPaidSubsInThePast && !!user.hadFreeTrialSubInThePast;
};
