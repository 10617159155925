import React, { useEffect } from 'react';

import Helmet from 'react-helmet';

import { DocTypes, SettingKeys } from '@invoice-simple/common';
import { FeatureName } from '@invoice-simple/feature-gate';

import SyncStatusIndicator from 'src/components/SyncStatusIndicator';
import { SETTING_LABELS } from 'src/data/settingsDisplayTypes';
import { defineMessages } from 'src/i18n';
import SettingListModel from '../../models/SettingListModel';
import SettingFormGroup from './SettingFormGroup';
import SettingFormGroupLogo from './SettingFormGroupLogo';

import './SettingList.css';

import { useFlags } from 'flagsmith/react';

import { useISIntl } from 'src/i18n/utils';
import { PaymentEventLocation, PaypalPromotionBox } from 'src/payments';
import { PaymentsQRSettingFormGroup } from 'src/payments/components/PaymentQRCode';
import { PassingFeesType, PaymentSetting } from 'src/payments/components/PaymentSetting';
import { SurchargeSetting } from 'src/payments/components/PaymentSetting/SurchargeSetting';
import { useIsPaymentsEnabled } from 'src/payments/hooks/useIsPaymentsEnabled';
import { useIsPaypalRedesignFlagEnabled } from 'src/payments/hooks/useIsPaypalRedesignFlagEnabled';
import { useTrackPaymentIntentInitialSetup } from 'src/payments/hooks/useTrackPaymentIntentInitialSetup';
import { AppStore } from 'src/stores/AppStore';
import { DocLimitBanner } from '../DocLimitBanner';
import AccountSettings from './AccountSettings';
import { ExportSpreadsheet } from './ExportSpreadsheet';
import { SettingsPayments } from './SettingsPayments';
import { SubscriptionSettings } from './SubscriptionSettings';

const messages = defineMessages({
  customize: {
    id: 'settings.list.customize',
    defaultMessage: 'Customize'
  },
  invoiceSimplePayments: {
    id: 'settings.list.invoiceSimplePayments',
    defaultMessage: 'Invoice Simple Payments'
  },
  invoiceTemplate: {
    id: 'templates.header',
    defaultMessage: 'Invoice Template'
  },
  businessDetails: {
    id: 'settings.list.businessDetails',
    defaultMessage: 'business details'
  },
  invoice: {
    id: 'app.invoice',
    defaultMessage: 'invoice'
  },
  invoices: {
    id: 'settings.list.invoices',
    defaultMessage: 'invoices'
  },
  estimate: {
    id: 'settings.list.estimate',
    defaultMessage: 'estimate'
  },
  estimates: {
    id: 'settings.list.estimates',
    defaultMessage: 'estimates'
  },
  title: {
    id: 'settings.list.title',
    defaultMessage: 'settings'
  },
  description: {
    id: 'settings.list.description',
    defaultMessage: 'update your settings'
  },
  company: {
    id: 'settings.list.company',
    defaultMessage: 'company'
  },
  companyName: {
    id: 'settings.list.company.name',
    defaultMessage: 'business name'
  },
  contactName: {
    id: 'settings.list.contact.name',
    defaultMessage: 'business owner name'
  },
  companyEmail: {
    id: 'settings.list.company.email',
    defaultMessage: 'email'
  },
  companyAddressLine: {
    id: 'settings.list.company.addressLine',
    defaultMessage: 'address line'
  },
  companyPhone: {
    id: 'settings.list.company.phone',
    defaultMessage: 'phone'
  },
  companyMobile: {
    id: 'settings.list.company.mobile',
    defaultMessage: 'mobile'
  },
  companyWebsite: {
    id: 'settings.list.company.website',
    defaultMessage: 'website'
  },
  businessNumber: {
    id: 'settings.list.company.businessNumber',
    defaultMessage: 'business number'
  },
  businessNumberLabel: {
    id: 'settings.list.company.businessNumberLabel',
    defaultMessage: 'business number label'
  },
  themeColor: {
    id: 'settings.list.theme.color',
    defaultMessage: 'color'
  },
  defaultEmailMessage: {
    id: 'settings.list.email.defaultMessage',
    defaultMessage: 'default email message'
  },
  tax: {
    id: 'settings.list.tax',
    defaultMessage: 'tax'
  },
  taxType: {
    id: 'settings.list.tax.type',
    defaultMessage: 'type'
  },
  taxRate: {
    id: 'settings.list.tax.rate',
    defaultMessage: 'rate'
  },
  taxLabel: {
    id: 'settings.list.tax.label',
    defaultMessage: 'label'
  },
  taxInclusive: {
    id: 'settings.list.tax.inclusive',
    defaultMessage: 'inclusive'
  },
  region: {
    id: 'settings.list.region',
    defaultMessage: 'region'
  },
  currency: {
    id: 'settings.list.currency',
    defaultMessage: 'currency'
  },
  taxYearBegins: {
    id: 'settings.list.tax.year.begins',
    defaultMessage: 'tax year begins'
  },
  locale: {
    id: 'settings.list.locale',
    defaultMessage: 'locale'
  },
  dateFormat: {
    id: 'settings.list.date.format',
    defaultMessage: 'date format'
  },
  docTitle: {
    id: 'settings.list.doc.title',
    defaultMessage: 'title'
  },
  invoiceTitle: {
    id: 'settings.list.invoice.title',
    defaultMessage: 'invoice title'
  },
  estimateTitle: {
    id: 'settings.list.estimate.title',
    defaultMessage: 'estimate title'
  },
  invoiceNumber: {
    id: 'settings.list.invoice.number',
    defaultMessage: 'invoice number'
  },
  estimateNumber: {
    id: 'settings.list.estimate.number',
    defaultMessage: 'estimate number'
  },
  quantityLabel: {
    id: 'settings.list.quantity.label',
    defaultMessage: 'quantity label'
  },
  itemRateLabel: {
    id: 'settings.list.itemRate.label',
    defaultMessage: 'unit cost label'
  },
  hideUnitCost: {
    id: 'settings.list.hideUnitCost',
    defaultMessage: 'show quantity and unit cost'
  },
  docLastNumber: {
    id: 'settings.list.doc.lastNumber',
    defaultMessage: 'last number'
  },
  docDefaultNotes: {
    id: 'settings.list.doc.defaultNotes',
    defaultMessage: 'default notes'
  },
  paymentInstructionsPaypal: {
    id: 'paymentInstructions.paypal',
    defaultMessage: 'paypal'
  },
  paymentInstructionsCheck: {
    id: 'paymentInstructions.check',
    defaultMessage: 'by check'
  },
  paymentInstructionsOther: {
    id: 'paymentInstructions.other',
    defaultMessage: 'other'
  },
  paymentInstructionsTitle: {
    id: 'paymentInstructions.title',
    defaultMessage: 'offline payment info'
  },
  paymentInstructionsSubtitle: {
    id: 'paymentInstructions.subtitle',
    defaultMessage: 'do not enter sensitive information'
  },
  paymentInstructionsPlaceholder: {
    id: 'paymentInstructions.placeholder',
    defaultMessage: 'specify instructions for payments or deposits'
  },
  paymentInstructionsLabel: {
    id: 'paymentInstructions.paymentLabel',
    defaultMessage: 'payment instructions'
  },
  paymentSettingsTitle: {
    id: 'paymentSetting.title',
    defaultMessage: 'online payments'
  },
  sendEmailCopy: {
    id: 'settings.list.send.email.copy',
    defaultMessage: 'send me a copy of emails'
  },
  clientSignatures: {
    id: 'settings.list.clientSignatures',
    defaultMessage: 'client signatures'
  },
  requireClientSignature: {
    id: 'settings.list.clientSignatures.requireClientSignature',
    defaultMessage: 'require clients to sign estimates'
  }
});

interface SettingListProps {
  store: AppStore;
  list: SettingListModel;
  onLoad: () => void;
}

export default function SettingList({ store, list, onLoad }: SettingListProps) {
  useTrackPaymentIntentInitialSetup({ store });
  useEffect(() => {
    onLoad();
  }, []);

  const isPaypalRedesignFlagEnabled = useIsPaypalRedesignFlagEnabled();
  const isPaymentsEnabled = useIsPaymentsEnabled();
  const intl = useISIntl();

  const { ft, fta, getTitleizedDocumentType } = intl;
  const { user } = store;

  const hasClientSignatureAccess = user.canUseFeature(FeatureName.CLIENT_SIGNATURE);
  const { client_signatures } = useFlags(['client_signatures']);

  return (
    <div className="container">
      <DocLimitBanner />
      <div className="setting-list">
        <Helmet>
          <title itemProp="name">{fta(messages.title)}</title>
          <meta name="description" content={ft(messages.description)} />
        </Helmet>

        <div className="page-header">
          <h1>{fta(messages.title)}</h1>
          <SyncStatusIndicator isLoading={list.isLoading} isSaving={list.isSaving} />
        </div>

        <div className="row">
          <div className="col-xs-12 col-lg-6">
            <div className="setting-list-body">
              <form>
                {isPaypalRedesignFlagEnabled && isPaymentsEnabled && (
                  <SettingsPayments
                    feesTypeSetting={list.getSetting(SettingKeys.PaymentFeesType)}
                    alwaysAddSurchargeSetting={list.getSetting(
                      SettingKeys.PaymentAlwaysAddSurcharge
                    )}
                    label={fta(messages.invoiceSimplePayments)}
                    qrCodeEnabledSetting={list.getSetting(SettingKeys.PaymentQRCodeEnabled)}
                  />
                )}

                <h4>{fta(messages.businessDetails)}</h4>
                <SettingFormGroupLogo
                  userEvents={user.events}
                  logo={list.logo}
                  disabled={list.isLoading}
                />
                <br />

                <SettingFormGroup
                  label={fta(messages.companyName)}
                  setting={list.getSetting(SettingKeys.CompanyName)}
                />
                <SettingFormGroup
                  label={fta(messages.contactName)}
                  setting={list.getSetting(SettingKeys.ContactName)}
                />
                <SettingFormGroup
                  label={fta(messages.businessNumber)}
                  setting={list.getSetting(SettingKeys.CompanyBusinessNumber)}
                />
                <br />
                <SettingFormGroup
                  label={`${fta(messages.companyAddressLine)} 1`}
                  setting={list.getSetting(SettingKeys.CompanyAddressLine1)}
                />
                <SettingFormGroup
                  label={`${fta(messages.companyAddressLine)} 2`}
                  setting={list.getSetting(SettingKeys.CompanyAddressLine2)}
                />
                <SettingFormGroup
                  label={`${fta(messages.companyAddressLine)} 3`}
                  setting={list.getSetting(SettingKeys.CompanyAddressLine3)}
                />
                <br />
                <SettingFormGroup
                  label={fta(messages.companyEmail)}
                  setting={list.getSetting(SettingKeys.ContactEmail)}
                />
                <SettingFormGroup
                  label={fta(messages.companyPhone)}
                  setting={list.getSetting(SettingKeys.ContactPhone)}
                />
                <SettingFormGroup
                  label={fta(messages.companyMobile)}
                  setting={list.getSetting(SettingKeys.ContactMobile)}
                />
                <SettingFormGroup
                  label={fta(messages.companyWebsite)}
                  setting={list.getSetting(SettingKeys.ContactWebsiteUrl)}
                />
                <SettingFormGroup
                  label={fta(messages.invoiceTemplate)}
                  setting={list.getSetting(SettingKeys.ThemeTemplate)}
                  meta={{ themeColorSetting: list.getSetting(SettingKeys.ThemeColor) }}
                />

                {client_signatures.enabled && (
                  <>
                    <h4>{fta(messages.clientSignatures)}</h4>
                    <SettingFormGroup
                      label={fta(messages.requireClientSignature)}
                      setting={list.getSetting(SettingKeys.EstimateSignatureRequired)}
                      showLockIcon={!hasClientSignatureAccess}
                    />
                  </>
                )}

                <h4>{fta(messages.tax)}</h4>
                <SettingFormGroup
                  label={fta(messages.taxType)}
                  setting={list.getSetting(SettingKeys.TaxType)}
                  wrapperClasses="tax-type"
                />
                <SettingFormGroup
                  label={fta(messages.taxRate)}
                  setting={list.getSetting(SettingKeys.TaxRate)}
                />
                <SettingFormGroup
                  label={fta(messages.taxLabel)}
                  setting={list.getSetting(SettingKeys.TaxLabel)}
                />
                <SettingFormGroup
                  label={fta(messages.taxInclusive)}
                  setting={list.getSetting(SettingKeys.TaxInclusive)}
                />
                <h4>{fta(messages.docDefaultNotes)}</h4>
                <SettingFormGroup
                  label={fta(messages.invoices)}
                  setting={list.getSetting(SettingKeys.DefaultInvoiceNote)}
                />
                <SettingFormGroup
                  label={fta(messages.estimates)}
                  setting={list.getSetting(SettingKeys.DefaultEstimateNote)}
                />

                <h4>{fta(messages.invoiceNumber)}</h4>
                <SettingFormGroup
                  label={fta(messages.invoiceNumber)}
                  setting={list.getSetting(SettingKeys.InvoiceLastNo)}
                />
                <SettingFormGroup
                  label={fta(messages.estimateNumber)}
                  setting={list.getSetting(SettingKeys.EstimateLastNo)}
                />

                <h4>{fta(messages.region)}</h4>
                <SettingFormGroup
                  label={fta(messages.locale)}
                  setting={list.getSetting(SettingKeys.LocaleLanguage)}
                />
                <SettingFormGroup
                  label={fta(messages.taxYearBegins)}
                  setting={list.getSetting(SettingKeys.LocaleTaxYearStartMonth)}
                />
                <SettingFormGroup
                  label={fta(messages.currency)}
                  setting={list.getSetting(SettingKeys.LocaleCurrencyCode)}
                />
                <SettingFormGroup
                  label={fta(messages.dateFormat)}
                  setting={list.getSetting(SettingKeys.LocaleDateFormat)}
                />

                <h4>{fta(messages.customize)}</h4>
                <SettingFormGroup
                  label={fta(messages.invoiceTitle)}
                  placeholder={getTitleizedDocumentType(DocTypes.DOCTYPE_INVOICE)}
                  setting={list.getSetting(SETTING_LABELS.INVOICE.TITLE)}
                />
                <SettingFormGroup
                  label={fta(messages.estimateTitle)}
                  placeholder={getTitleizedDocumentType(DocTypes.DOCTYPE_ESTIMATE)}
                  setting={list.getSetting(SettingKeys.RenameEstimateTitle)}
                />
                <SettingFormGroup
                  label={fta(messages.businessNumberLabel)}
                  setting={list.getSetting(SettingKeys.RenameCompanyBusinessNumber)}
                />
                <SettingFormGroup
                  label={fta(messages.quantityLabel)}
                  setting={list.getSetting(SettingKeys.RenameItemQuantity)}
                />
                <SettingFormGroup
                  label={fta(messages.itemRateLabel)}
                  setting={list.getSetting(SettingKeys.RenameItemRate)}
                />
                <SettingFormGroup
                  label={fta(messages.hideUnitCost)}
                  reverse={true}
                  setting={list.getSetting(SettingKeys.InvoiceHideQuantityAndRate)}
                />
                <SettingFormGroup
                  label={fta(messages.defaultEmailMessage)}
                  setting={list.getSetting(SETTING_LABELS.DEFAULT_EMAIL_MESSAGE)}
                />
                <SettingFormGroup
                  label={fta(messages.sendEmailCopy)}
                  setting={list.getSetting(SettingKeys.EmailCopySender)}
                />
                <ExportSpreadsheet user={user} />

                {!isPaypalRedesignFlagEnabled && isPaymentsEnabled && (
                  <>
                    <div data-testid="oldStripePaymentSection" />
                    <PaymentSetting
                      dictionary={{
                        acceptPayments: 'Accept Online Payments',
                        paypalAccountEmail: 'PayPal Email',
                        onlinePayments: 'Online Payments',
                        paypalMerchantId: 'PayPal Account ID'
                      }}
                      onTrackEvent={(eventName, metadata = {} as any) => {
                        list.user.trackAppEventViaApi(eventName, {
                          ...metadata,
                          location: metadata.location || PaymentEventLocation.paymentConfigSettings
                        });
                      }}
                    />
                    <SurchargeSetting
                      transactionFeeslabel="Cover Processing Fees"
                      feesTypeSetting={list.getSetting(SettingKeys.PaymentFeesType)}
                      options={[
                        {
                          id: 'passing-fees-markup',
                          value: PassingFeesType.MARKUP,
                          label: 'Mark up all items'
                        },
                        {
                          id: 'passing-fees-surcharge',
                          value: PassingFeesType.SURCHARGE,
                          label: 'Add surcharge when invoice paid online'
                        }
                      ]}
                      alwaysAddSurchargeSetting={list.getSetting(
                        SettingKeys.PaymentAlwaysAddSurcharge
                      )}
                      alwaysAddSurchargeSettingLabel={'Always add surcharge'}
                    />
                  </>
                )}
                <h4 id="payments-instructions">
                  <span>{fta(messages.paymentInstructionsTitle)}</span>
                  <span className="subtitle">({fta(messages.paymentInstructionsSubtitle)})</span>
                </h4>
                {!isPaypalRedesignFlagEnabled && isPaymentsEnabled && (
                  <>
                    <div data-testid="oldQRCodePaymentSection" />
                    <PaymentsQRSettingFormGroup
                      setting={list.getSetting(SettingKeys.PaymentQRCodeEnabled)}
                    />
                  </>
                )}
                <SettingFormGroup
                  label={fta(messages.paymentInstructionsPaypal)}
                  setting={list.getSetting(SettingKeys.PaymentPaypalEmail)}
                />
                <SettingFormGroup
                  placeholder={ft(messages.paymentInstructionsPlaceholder)}
                  label={fta(messages.paymentInstructionsLabel)}
                  setting={list.getSetting(SettingKeys.PaymentBank)}
                />
                <SettingFormGroup
                  label={fta(messages.paymentInstructionsCheck)}
                  setting={list.getSetting(SettingKeys.PaymentCheque)}
                />
                <SettingFormGroup
                  label={fta(messages.paymentInstructionsOther)}
                  setting={list.getSetting(SettingKeys.PaymentOther)}
                />
                {!!user.lastActivePaidWebSub() && (
                  <SubscriptionSettings
                    showAnnualUpgrade={!!user.lastActivePaidWebSub()?.isMonthly}
                    accountEmail={user.email}
                  />
                )}
                {!user.isGuest && <AccountSettings user={user} />}
              </form>
            </div>
          </div>
          {!isPaypalRedesignFlagEnabled && isPaymentsEnabled && (
            <div className="col-xs-12 col-lg-4 order-first order-lg-12">
              <PaypalPromotionBox
                location={PaymentEventLocation.promotionBoxSettings}
                onTrackEvent={(eventName, metadata = {}) => {
                  list.user.trackAppEventViaApi(eventName, {
                    ...metadata,
                    location: PaymentEventLocation.promotionBoxSettings
                  });
                }}
                store={store}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
