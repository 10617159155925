import { useFlags } from 'flagsmith/react';

import { SubscriptionTier } from '@invoice-simple/common';
import { FeatureName } from '@invoice-simple/feature-gate';

import { useISIntl } from 'src/i18n';
import UserModel, { Cadence } from 'src/models/UserModel';
import titleize from 'src/util/Titleize';
import { hasValidDiscount } from './discount.utils';
import { messages } from './messages';
import { getFormattedCurrency } from './paywall.utils';

export const orderedTiers = [
  SubscriptionTier.ESSENTIALS,
  SubscriptionTier.PLUS,
  SubscriptionTier.PREMIUM
];

/**
 * Get new tier equivalent from old tier.
 */
export function getNewTierFromOldTier(tier: SubscriptionTier): SubscriptionTier {
  switch (tier) {
    case SubscriptionTier.STARTER:
      return SubscriptionTier.ESSENTIALS;
    case SubscriptionTier.PRO:
      return SubscriptionTier.PLUS;
  }
  return tier;
}

/**
 * Get old tier equivalent from new tier.
 */
export function maybeGetOldTierFromNewTier(tier: SubscriptionTier): SubscriptionTier {
  // No need to map to old tier name if we're offering new tiered pricing
  if (UserModel.getInstance().shouldOfferNewTieredPricing) {
    return tier;
  }

  switch (tier) {
    case SubscriptionTier.PREMIUM_LEGACY:
      return SubscriptionTier.PREMIUM;
    case SubscriptionTier.ESSENTIALS:
      return SubscriptionTier.STARTER;
    case SubscriptionTier.PLUS:
      return SubscriptionTier.PRO;
  }
  return tier;
}

export function getTierRequired(
  reasonForUpgrade:
    | FeatureName
    | '3-invoice-limit'
    | '10-invoice-limit'
    | 'plus-direct-link'
    | 'premium-direct-link'
    | null
): SubscriptionTier | null {
  if (!reasonForUpgrade) return null;

  const featureToTierMap = {
    [FeatureName.INVOICE_PHOTOS]: SubscriptionTier.PLUS,
    [FeatureName.SIGNATURE]: SubscriptionTier.PLUS,
    [FeatureName.REQUEST_REVIEW]: SubscriptionTier.PLUS,
    [FeatureName.DUE_DATE_REMINDERS]: SubscriptionTier.PLUS,
    [FeatureName.REPORTS]: SubscriptionTier.PLUS,
    [FeatureName.EXPENSES]: SubscriptionTier.PLUS,
    [FeatureName.CLIENT_SIGNATURE]: SubscriptionTier.PREMIUM,
    [FeatureName.DEPOSITS]: SubscriptionTier.PREMIUM,
    [FeatureName.PREMIUM_TEMPLATES]: SubscriptionTier.PREMIUM,
    [FeatureName.VERSION_HISTORY]: SubscriptionTier.PREMIUM,
    '3-invoice-limit': SubscriptionTier.PLUS,
    '10-invoice-limit': SubscriptionTier.PREMIUM,
    'plus-direct-link': SubscriptionTier.PLUS,
    'premium-direct-link': SubscriptionTier.PREMIUM
  };

  return featureToTierMap[reasonForUpgrade];
}

export enum SubscriptionSwitchType {
  UPGRADE = 'upgrade',
  CROSSGRADE = 'crossgrade',
  DOWNGRADE = 'downgrade'
}

export function getSubscriptionSwitchType(
  oldTier: SubscriptionTier | null,
  newTier: SubscriptionTier
): SubscriptionSwitchType {
  if (oldTier === newTier) {
    return SubscriptionSwitchType.CROSSGRADE;
  }
  if (isDowngradingTier(oldTier, newTier)) {
    return SubscriptionSwitchType.DOWNGRADE;
  }
  return SubscriptionSwitchType.UPGRADE;
}

function isDowngradingTier(oldTier: SubscriptionTier | null, newTier: SubscriptionTier): boolean {
  if (oldTier === null) {
    return false;
  }
  return subscriptionTierPriority[oldTier] < subscriptionTierPriority[newTier];
}

export function isEndOfTermSwitch(switchType: SubscriptionSwitchType): boolean {
  return (
    switchType === SubscriptionSwitchType.CROSSGRADE ||
    switchType === SubscriptionSwitchType.DOWNGRADE
  );
}

/* Descending priority order */
const subscriptionTierPriority: Record<SubscriptionTier, number> = {
  [SubscriptionTier.PREMIUM]: 0,
  [SubscriptionTier.PREMIUM_LEGACY]: 1,
  [SubscriptionTier.PLUS_LEGACY]: 2,
  [SubscriptionTier.ESSENTIALS_LEGACY]: 3,
  [SubscriptionTier.PAYMENTS_TIER]: 4,
  [SubscriptionTier.PRO]: 5,
  [SubscriptionTier.PLUS]: 6,
  [SubscriptionTier.STARTER]: 7,
  [SubscriptionTier.ESSENTIALS]: 8
};

export type TierCardDataType = {
  name: SubscriptionTier;
  description: string;
  featureTitle?: string;
  features: string[];
};

export const tierCardData = (): TierCardDataType[] => {
  const { ft } = useISIntl();
  const { client_signatures, version_history_web } = useFlags([
    'client_signatures',
    'version_history_web'
  ]);

  return [
    {
      name: SubscriptionTier.ESSENTIALS,
      description: ft(messages.essentialsPlanDesc),
      features: [
        ft(messages.invoicesPerMonthAmount, { invoices: 3 }),
        ft(messages.onlineCollaboration),
        ft(messages.creditCardProcessing),
        ft(messages.featureQR),
        ft(messages.acceptDeposits)
      ]
    },
    {
      name: SubscriptionTier.PLUS,
      description: ft(messages.plusPlanDesc),
      featureTitle: ft(messages.everythingInTier, { tier: titleize(SubscriptionTier.ESSENTIALS) }),
      features: [
        ft(messages.invoicesPerMonthAmount, { invoices: 10 }),
        ft(messages.businessOwnerSignature),
        ft(messages.businessSummaryReports),
        ft(messages.featureDueDateReminders)
      ]
    },
    {
      name: SubscriptionTier.PREMIUM,
      description: ft(messages.premiumPlanDesc),
      featureTitle: ft(messages.everythingInTier, { tier: titleize(SubscriptionTier.PLUS) }),
      features: [
        ft(messages.unlimitedInvoices),
        ...(client_signatures.enabled ? [ft(messages.featureClientSignature)] : []),
        ft(messages.clientSignatures),
        ft(messages.featurePremiumTemplates),
        ft(messages.deposits),
        ...(version_history_web.enabled ? [ft(messages.versionHistory)] : [])
      ]
    }
  ];
};

export const getTierPrice = (tier: SubscriptionTier, cadence: Cadence) => {
  const user = UserModel.getInstance();
  const couponInfo = user.paywallCouponInfo;

  const regularPrice = getFormattedCurrency(user, user.getGeoSubAmount({ tier, cadence }) / 100);

  const hasDiscount = couponInfo && hasValidDiscount(couponInfo, cadence);

  if (hasDiscount) {
    const displayPrice = getFormattedCurrency(
      user,
      user.getGeoSubAmount({
        tier,
        cadence,
        coupon: couponInfo
      }) / 100
    );

    return { comparePrice: regularPrice, displayPrice };
  }

  const comparePrice =
    cadence === Cadence.ANNUAL
      ? getFormattedCurrency(
          user,
          (user.getGeoSubAmount({ tier, cadence: Cadence.MONTHLY }) / 100) * 12
        )
      : undefined;

  return { comparePrice, displayPrice: regularPrice };
};
