import { useFlags } from 'flagsmith/react';

import { usePaypalContext } from '../contexts/PaypalAccountContext';
import { useStripeContext } from '../contexts/StripeAccountContext';
import { PaymentsProviderName } from '../types';
import { useIsPaymentsLegacyUser } from './useIsPaymentsLegacyUser';
import { useShouldDisplaySaasPayments } from './useIsSaasPayments';

export interface PaymentsDashboardEligibility {
  eligible: PaymentsProviderName[];
  initial: PaymentsProviderName[];
  pending: PaymentsProviderName[];
  accepting: PaymentsProviderName[];
  restricted: PaymentsProviderName[];
  rejected: PaymentsProviderName[];
  paymentsTabHref: string | null;
}

export const usePaymentsDashboardEligibility = (): PaymentsDashboardEligibility => {
  const { payments_enabled, new_payments_dashboard } = useFlags([
    'payments_enabled',
    'new_payments_dashboard'
  ]);
  const paypalAccountContext = usePaypalContext();
  const stripeAccountContext = useStripeContext();
  const isLegacyUser = useIsPaymentsLegacyUser();
  const shouldDisplaySaasPayments = useShouldDisplaySaasPayments();

  const eligibility: PaymentsDashboardEligibility = {
    eligible: [],
    initial: [],
    pending: [],
    accepting: [],
    restricted: [],
    rejected: [],
    paymentsTabHref: null
  };

  if (!payments_enabled?.enabled || (!new_payments_dashboard?.enabled && !isLegacyUser)) {
    return eligibility;
  }

  if (!shouldDisplaySaasPayments) {
    return eligibility;
  }

  if (!stripeAccountContext?.stripeStatus || !paypalAccountContext?.paypalStatus) {
    return eligibility;
  }

  const {
    isStripeEligible,
    isStripeSubsetOfAccepting,
    isStripePending,
    isStripePendingVerification,
    isStripeInitial,
    isStripeRejected,
    isStripeRestricted
  } = stripeAccountContext;

  const {
    isPaypalEligible,
    isPaypalAccepting,
    isPaypalPending,
    isPaypalInitial,
    isPaypalOnboarded,
    isPaypalClosed
  } = paypalAccountContext;

  if (!isPaypalEligible() && !isStripeEligible) {
    return eligibility;
  }

  if (isPaypalEligible()) {
    eligibility.eligible.push(PaymentsProviderName.PAYPAL);
  }
  if (isPaypalInitial()) {
    eligibility.initial.push(PaymentsProviderName.PAYPAL);
  }
  if (isPaypalAccepting() || isPaypalClosed()) {
    eligibility.accepting.push(PaymentsProviderName.PAYPAL);
  }
  if (isPaypalPending() || isPaypalOnboarded()) {
    eligibility.pending.push(PaymentsProviderName.PAYPAL);
  }

  if (isLegacyUser) {
    const isEligible = eligibility.eligible.includes(PaymentsProviderName.PAYPAL);

    if (!isEligible) {
      return eligibility;
    }

    let paymentsTabHref = '/paypal/learnmore';

    const isAccepting = eligibility.accepting.includes(PaymentsProviderName.PAYPAL);
    if (isAccepting) {
      paymentsTabHref = '/payments/dashboard';
    }
    return {
      ...eligibility,
      paymentsTabHref
    };
  }

  if (isStripeEligible) {
    eligibility.eligible.push(PaymentsProviderName.STRIPE);
  }
  if (isStripeInitial) {
    eligibility.initial.push(PaymentsProviderName.STRIPE);
  }
  if (isStripePending || isStripePendingVerification) {
    eligibility.pending.push(PaymentsProviderName.STRIPE);
  }
  if (isStripeSubsetOfAccepting) {
    eligibility.accepting.push(PaymentsProviderName.STRIPE);
  }
  if (isStripeRejected) {
    eligibility.rejected.push(PaymentsProviderName.STRIPE);
  }
  if (isStripeRestricted) {
    eligibility.restricted.push(PaymentsProviderName.STRIPE);
  }

  return {
    ...eligibility,
    paymentsTabHref: '/payments/dashboard'
  };
};
