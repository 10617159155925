import type { UpgradeSubscriptionParams } from 'src/stores/AppStore';
import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import { Platform } from '@invoice-simple/common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cancelSubscription } from 'src/apis/subscriptionsAPI';
import { XCircleIcon } from 'src/components/ColorSelect/Icons';
import AlertModel from 'src/models/AlertModel';
import UserModel from 'src/models/UserModel';
import { useCancelPromo } from '../CancelPromoContext';
import { useCancelSubscription } from '../CancelSubscriptionContext';
import { Discount } from '../components/Discount';
import { ReturnToAppButton } from '../components/ReturnToAppButton';
import { CANCEL_PROMO_COUPON } from '../types';
import { trackMobileWebviewEvent } from '../utils/trackMobileWebviewEvent';

interface Props {
  user: UserModel;
  upgradeSubscription: (args: UpgradeSubscriptionParams) => Promise<void>;
  platform: Platform;
  onError: (error: { title?: string; body?: string }) => void;
}

const ValuePropsScreen = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const { goTo } = useCancelSubscription();
  const { cancelPromoEnabled } = useCancelPromo();

  useEffect(() => {
    if (props.platform === Platform.WEB) {
      props.user.events.trackAction('subscription-cancel-pageViewed', {
        page: 'offer',
        'coupon-sku': cancelPromoEnabled ? CANCEL_PROMO_COUPON : undefined
      });
      return;
    }

    trackMobileWebviewEvent({
      event: 'webview',
      meta: {
        feature: 'cancellation-survey',
        action: 'offer-page-viewed',
        platform: props.platform,
        'coupon-sku': cancelPromoEnabled ? CANCEL_PROMO_COUPON : undefined
      }
    });
  }, []);

  const handleCancelAnyway = async (): Promise<void> => {
    if (props.platform === Platform.WEB) {
      try {
        setLoading(true);
        await cancelSubscription(props.user);
        return goTo('completed');
      } catch (e) {
        return AlertModel.setAlert(
          'danger',
          'Unable to cancel subscription',
          'Please try again or contact support.'
        );
      } finally {
        setLoading(false);
      }
    }

    trackMobileWebviewEvent({
      event: 'webview',
      meta: {
        feature: 'cancellation-survey',
        action: 'cancel-anyway',
        platform: props.platform
      }
    });
    window.location.href = 'settings://subscription-cancel';
  };

  return (
    <div className="value-props-screen" style={{ fontFamily: 'Quicksand' }}>
      <div className="flex flex-col justify-center content-center">
        <img
          className="h-24 sm:h-28 rounded self-center"
          src="images/is-logo.png"
          alt="Invoice Simple Logo"
        />
        <h1
          data-testid="value-props-title"
          role="heading"
          className="mt-12 self-center font-bold leading-10 text-gray-800">
          Before you go
        </h1>
        <div
          className="mt-16 p-16 w-11/12 self-center rounded"
          style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}>
          <span className="text-4xl font-semibold text-gray-700">Here’s what you’ll lose</span>
          <p className="text-2xl text-gray-800 flex items-center" style={{ marginTop: '20px' }}>
            {XCircleIcon}
            Ability to send invoices
          </p>
          <p className="text-2xl text-gray-800 flex items-center" style={{ marginTop: '10px' }}>
            {XCircleIcon}
            Customer read receipts
          </p>
          <p className="text-2xl text-gray-800 flex items-center" style={{ marginTop: '10px' }}>
            {XCircleIcon}
            Managing your clients and items
          </p>
        </div>
        <Discount user={props.user} upgradeSubscription={props.upgradeSubscription} />
        {!cancelPromoEnabled && <ReturnToAppButton user={props.user} platform={props.platform} />}
        <button
          data-testid="cancel-subscription-button"
          type="button"
          className="self-center text-2xl bg-transparent text-blue-500 font-semibold py-2 px-4 outline-none border-0 underline cancel-button mt-16"
          onClick={handleCancelAnyway}>
          No thanks, I want to cancel
          {loading && <FontAwesomeIcon className="mx-2" icon="spinner" spin />}
        </button>
      </div>
    </div>
  );
};

export default observer(ValuePropsScreen);
