import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import { Platform } from '@invoice-simple/common';

import { Loading } from 'src/components/Loading';
import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';
import { AppStore } from 'src/stores/AppStore';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import { CancelPromoProvider } from './CancelPromoContext';
import { CancelSubscriptionProvider, useCancelSubscription } from './CancelSubscriptionContext';
import { TypeFormSurveyID } from './common';
import CancelSuccessScreen from './screens/CancelSuccessScreen';
import FeedbackScreen from './screens/FeedbackScreen';
import ValuePropsScreen from './screens/ValuePropsScreen';

const messages = defineMessages({
  title: {
    id: 'subscription.cancel.title',
    defaultMessage: 'Cancel Subscription'
  }
});

interface Props {
  store: AppStore;
  onError: (error: { title?: string; body?: string }) => void;
  onLoad: () => void;
}

const _CancelSubscription = (props: Props) => {
  const intl = useISIntl();
  useEffect(() => {
    props.onLoad();
  }, []);
  const { isCancelSuccessScreen, isFeedbackScreen, isValuePropsScreen } = useCancelSubscription();

  if (!props.store.user.isLoaded) return <Loading />;

  const f = intl.formatMessage;

  const platform = (getURLQueryParam(URLQueryParamKeys.PLATFORM) as Platform) || Platform.WEB;
  const screenProps = {
    user: props.store.user,
    upgradeSubscription: props.store.upgradeSubscription,
    platform,
    intl,
    onError: props.onError
  };

  const isMobile = [Platform.ANDROID, Platform.IOS].includes(platform);

  return (
    <div id="tailwind">
      <Helmet>
        <title itemProp="name">{f(messages.title)}</title>
        <body className="app-theme cancel-subscription-theme" />
      </Helmet>

      <div className={`max-w-4xl mx-auto pb-4 ${isMobile ? 'mt-16' : 'mt-64'}`}>
        {isFeedbackScreen() && (
          <FeedbackScreen formId={TypeFormSurveyID.CANCEL_SURVEY_V2} {...screenProps} />
        )}
        {isValuePropsScreen() && <ValuePropsScreen {...screenProps} />}
        {isCancelSuccessScreen() && <CancelSuccessScreen {...screenProps} />}
      </div>
    </div>
  );
};

const CancelSubscription = observer(_CancelSubscription);

export default (props: Props) => {
  return (
    <CancelSubscriptionProvider>
      <CancelPromoProvider user={props.store.user}>
        <CancelSubscription {...props} />
      </CancelPromoProvider>
    </CancelSubscriptionProvider>
  );
};
